import {List, ListItem} from '@mui/material';
import type {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';

import CycleBar from '../components/cycleBar/CycleBar';
import type {CycleItem} from '../hooks/cycle/cycles';
import {useCycleListData} from '../hooks/cycle/cycles';

export default function CycleList(props: {}): ReactElement {
    const i18n = useTranslation();
    // TODO: pass model to get data
    const {cycles} = useCycleListData();

    /**
     * construct custom date string from Cycle information
     * @param {Date} dateStarted
     * @param {number} cycleLength
     * @param {boolean} [currentCycle=false]
     * @returns
     */
    function getCycleHeaderDateText(
        dateStarted: Date,
        cycleLength: number,
        currentCycle: boolean = false
    ): string {
        const startDateDay =
            dateStarted.getDate() > 9 ? dateStarted.getDate() : `0${dateStarted.getDate()}`;
        const startDateMonth = i18n.t(`common:date.monthOfYear${dateStarted.getMonth() + 1}`);

        if (currentCycle) {
            return `${startDateDay}. ${startDateMonth.toUpperCase()} - ${i18n
                .t('insights:currentCycle')
                .toUpperCase()}`;
        } else {
            const startDateYear = dateStarted.getFullYear();
            const endDate = new Date(dateStarted);
            endDate.setDate(endDate.getDate() + cycleLength);
            const endDateDay =
                dateStarted.getDate() > 9 ? dateStarted.getDate() : `0${dateStarted.getDate()}`;
            const endDateMonth = i18n.t(`common:date.monthOfYear${dateStarted.getMonth() + 1}`);
            const endDateYear = dateStarted.getFullYear();
            return `${startDateDay}. ${startDateMonth.toUpperCase()} ${startDateYear} - ${endDateDay}. ${endDateMonth.toUpperCase()} ${endDateYear}`;
        }
    }

    /**
     * layout of a ListItem for a single cycle
     * @param {Cycle} cycle
     * @param {number} index
     * @returns
     */
    function getCycleListItem(cycle: CycleItem, index: number): ReactElement {
        return (
            <ListItem key={index} className="insights-cycle">
                <div className="insights-cycle-header">
                    <div className="insights-cycle-header-date">
                        {getCycleHeaderDateText(
                            cycle.dateStarted,
                            cycle.length,
                            cycle.currentDay !== undefined
                        )}
                        {cycle.currentDay
                            ? ''
                            : ` / ${cycle.length} ${i18n.t('insights:days').toUpperCase()}`}
                    </div>
                </div>
                <div className="insights-cycle-bar">
                    <CycleBar
                        currentDay={cycle.currentDay}
                        cycleLength={cycle.length}
                        segmentSections={cycle.segmentSections}
                    />
                </div>
            </ListItem>
        );
    }
    return <List>{cycles.map(getCycleListItem)}</List>;
}
