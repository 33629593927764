import {fetchFile} from 'one.models/lib/misc/fetchFile';

type Config = {
    enableLogging: boolean;
    logTypes: ('error' | 'alert' | 'log' | 'debug')[];
};

/**
 * Check that the passed object conforms to the config type.
 *
 * @param arg
 */
export function isConfig(arg: any): arg is Config {
    const validLogTypes = ['error', 'alert', 'log', 'debug'];

    return (
        arg != null &&
        typeof arg.enableLogging === 'boolean' &&
        Array.isArray(arg.logTypes) &&
        arg.logTypes.filter((arg: any) => !validLogTypes.includes(arg)).length === 0
    );
}

/**
 * Load a config file.
 *
 * @param url - A url to a remote location. If relative, it is relative to the loaded app.
 */
export async function loadConfig(url: string): Promise<Config> {
    const config = JSON.parse(await fetchFile(url));

    if (!isConfig(config)) {
        throw new Error('Format of config file is wrong.');
    }

    return config;
}
