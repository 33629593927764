import {useCallback, useEffect, useState} from 'react';
import {format, differenceInDays} from 'date-fns';

import type Model from '../../../models/Model';
import type {Expanded, MeasurementBleeding} from '../../../models/recipes';
import type {DateFormatString} from '../../../models/typeutils';

/**
 * @param {string} stringDate `year-month-day`
 * @returns {Date}
 */
function getDate(stringDate: string): Date {
    const [year, month, day] = stringDate.split('-');
    return new Date(`${month}/${day}/${year}`);
}

async function getBleedingMeasurements(
    fromDate: Date,
    toDate: Date,
    model: Model
): Promise<(MeasurementBleeding | Expanded<MeasurementBleeding>)[]> {
    const measurements = await model.getMeasurements('MeasurementBleeding');
    const filteredMeasurements: (MeasurementBleeding | Expanded<MeasurementBleeding>)[] = [];
    const fromTime = fromDate.getTime();
    const toTime = toDate.getTime();
    for (let i = 0; i < measurements.length; i++) {
        const time = getDate(measurements[i].day).getTime();
        if (time >= fromTime && time <= toTime) {
            filteredMeasurements.push(measurements[i]);
        }
    }
    return filteredMeasurements;
}

async function getBleedingMeasurement(
    date: Date,
    model: Model
): Promise<Expanded<MeasurementBleeding> | MeasurementBleeding | undefined> {
    const measurements = await model.getMeasurements('MeasurementBleeding');
    const formattedDate = format(date, 'yyyy-mm-dd');
    let todayMeasurement: Expanded<MeasurementBleeding> | MeasurementBleeding | undefined =
        undefined;
    for (let i = 0; i < measurements.length; i++) {
        if (measurements[i].day === formattedDate) {
            todayMeasurement = measurements[i];
        }
    }
    return todayMeasurement;
}

export function useBleedingQuantity(
    date: Date,
    model: Model
): {
    quantity: MeasurementBleeding['value'] | undefined;
    setQuantity: (quantity: MeasurementBleeding['value']) => void;
} {
    const [bleedingQuantity, setBleedingQuantity] = useState<
        MeasurementBleeding['value'] | undefined
    >(undefined);

    const setModelData = useCallback(
        async (quantity: MeasurementBleeding['value']) => {
            const data: Expanded<MeasurementBleeding> = {
                $type$: 'MeasurementBleeding',
                day: format(date, 'yyyy-mm-dd') as DateFormatString,
                value: quantity,
                entered_at: {
                    $type$: 'DateTime',
                    utcTimestamp: date.getTime(),
                    // TODO: needed?
                    timezone: 'Europe/Berlin'
                }
            };
            model.addMeasurement(data);
        },
        [date, model]
    );

    useEffect(() => {
        async function getBleeding() {
            const todayMeasurement = await getBleedingMeasurement(date, model);
            if (todayMeasurement) {
                setBleedingQuantity(todayMeasurement.value);
            } else {
                setBleedingQuantity(undefined);
            }
        }
        getBleeding();
    }, [date, model]);

    return {
        quantity: bleedingQuantity,
        setQuantity: setModelData
    };
}

export function useBleedingMeasurements(
    model: Model,
    fromDate?: Date,
    toDate?: Date
): Expanded<(MeasurementBleeding | Expanded<MeasurementBleeding>)[]> {
    const [measurements, setMeasurements] = useState<
        (MeasurementBleeding | Expanded<MeasurementBleeding>)[]
    >([]);

    useEffect(() => {
        async function getBleeding() {
            if (fromDate && toDate) {
                const measurements = await getBleedingMeasurements(fromDate, toDate, model);
                setMeasurements(measurements);
            }
        }
        getBleeding();
    }, [model]);

    return measurements;
}

export function useDaysMeasured(model: Model, fromDate?: Date, toDate?: Date): number[] {
    const measurements = useBleedingMeasurements(model, fromDate, toDate);
    return measurements.map(measurement => {
        const measuredDate = getDate(measurement.day);
        if (fromDate) {
            return differenceInDays(fromDate, measuredDate);
        }
        return 0;
    });
}
