import type {
    OneCrdtObjectTypes,
    OneObjectTypeNames,
    Recipe,
    RecipeRule
} from '@refinio/one.core/lib/recipes';
import type {SHA256Hash, SHA256IdHash} from '@refinio/one.core/lib/util/type-checks';
import type {DateFormatString} from './typeutils';

export interface MeasurementTypes {
    MeasurementBleeding: MeasurementBleeding;
    MeasurementAbdominalPain: MeasurementAbdominalPain;
}

export interface AppDataTypes extends MeasurementTypes {
    Cycle: Cycle;
}

export type Expanded<T> = {
    [P in keyof T]: T[P] extends SHA256Hash<infer J> ? J : T[P];
};
declare module '@OneObjectInterfaces' {
    export interface OneUnversionedObjectInterfaces {
        DateTime: DateTime;
        Wrapper: Wrapper<OneCrdtObjectTypes>;
    }

    export interface OneCrdtIdObjectInterfaces {
        MeasurementBleeding: Pick<MeasurementBleeding, 'day' | '$type$'>;
        MeasurementAbdominalPain: Pick<MeasurementAbdominalPain, 'day' | '$type$'>;
        Cycle: Pick<Cycle, 'id' | '$type$'>;
    }

    export interface OneCrdtObjectInterfaces extends AppDataTypes {}
}

export interface DateTime {
    $type$: 'DateTime';
    utcTimestamp: number; // UTC timestamp
    timezone: string; // The IANA timezone idetification string
}

export function isDateTime(obj: any): obj is DateTime {
    return obj && obj?.$type$ === 'DateTime';
}

// TODO: Add regex constrains for timezone?
export const DateTimeRecipe: Recipe = {
    $type$: 'Recipe',
    name: 'DateTime',
    rule: [
        {
            itemprop: 'utcTimestamp',
            itemtype: {type: 'number'}
        },
        {
            itemprop: 'timezone',
            itemtype: {type: 'string'}
        }
    ]
};

export interface Measurement {
    // TODO: Maybe change that to an actual Date object. However than we need to be very careful with timezones when we create the objects.
    // The actual date of the day the recording should belong. If there can only be one measurement per day, this can be used as id, otherwise a dedicated id should be used to track which measurment on a day an object referes to.
    // This is the datetime (with the timezone) at the moment the user created the measurement in the app.
    // If the user updates the entry, this value will be changed to serve as an "edit log".
    // We keep that value explcitly and not just rely on the timestamps recorded by One, to track the timezone as well.
    day: string;
    // The entered_at timestamp does not need to match the day (recordings can be entered later/changed).
    // We should properly have check somewhere, that the value was not entered before the day.
    // However, we need to be careful with timezones, when we do that.
    entered_at: SHA256Hash<DateTime>;
    value: any;
}

export interface MeasurementBleeding extends Measurement {
    $type$: 'MeasurementBleeding';
    value: 'none' | 'spotting' | 'light' | 'medium' | 'heavy';
}

export interface MeasurementAbdominalPain extends Measurement {
    $type$: 'MeasurementAbdominalPain';
    value: 'none' | 'light' | 'medium' | 'heavy';
}

// Does not contain "value", because the dtype will change for each measurement
const BaseMeasurementRules: RecipeRule[] = [
    {
        itemprop: 'day',
        isId: true,
        // TODO: Add regex constrain for dateformat
        itemtype: {type: 'string'}
    },
    {
        itemprop: 'entered_at',
        // TODO: The explicit `OneObjectTypeNames` here should not be required. Need to figure out waht goes wrong.
        itemtype: {type: 'referenceToObj', allowedTypes: new Set<OneObjectTypeNames>(['DateTime'])}
    }
];

export const MeasurementBleedingRecipe: Recipe = {
    $type$: 'Recipe',
    name: 'MeasurementBleeding',
    rule: [
        ...BaseMeasurementRules,
        {
            itemprop: 'value',
            itemtype: {type: 'string', regexp: /none|spotting|light|medium|heavy/}
        }
    ],
    crdtConfig: new Map()
};

export const MeasurementAbdominalPainRecipe: Recipe = {
    $type$: 'Recipe',
    name: 'MeasurementAbdominalPain',
    rule: [
        ...BaseMeasurementRules,
        {
            itemprop: 'value',
            itemtype: {type: 'string', regexp: /none|light|medium|heavy/}
        }
    ],
    crdtConfig: new Map()
};

export interface Cycle {
    $type$: 'Cycle';
    id: number; // We need an id (the n-th cycle recorded), as the start date might change
    startDay: DateFormatString;
    excluded: boolean;
}

export const CycleRecipe: Recipe = {
    $type$: 'Recipe',
    name: 'Cycle',
    rule: [
        {
            itemprop: 'id',
            isId: true,
            itemtype: {type: 'number'}
        },
        {
            itemprop: 'startDay',
            itemtype: {type: 'string'}
        },
        {
            itemprop: 'excluded',
            itemtype: {type: 'boolean'}
        }
    ],
    crdtConfig: new Map()
};

// We need wrappers for all the versioned objects to be able to throw them into channels

const versionedRecipes = [
    MeasurementBleedingRecipe,
    MeasurementAbdominalPainRecipe,
    CycleRecipe
] as const;

interface Wrapper<T extends OneCrdtObjectTypes> {
    $type$: 'Wrapper';
    data: SHA256IdHash<T>;
}

const WrapperRecipe: Recipe = {
    $type$: 'Recipe',
    name: 'Wrapper',
    rule: [
        {
            itemprop: 'data',
            itemtype: {
                type: 'referenceToId',
                allowedTypes: new Set<'*'>(['*'])
            }
        }
    ]
};

const AppRecipes: Recipe[] = [DateTimeRecipe, ...versionedRecipes, WrapperRecipe];

export default AppRecipes;
