import type {ReactElement} from 'react';
import {useEffect, useState} from 'react';

import type {Position} from '../../../types/svg';
import type {CurrentDayMarkerOptions, TrackerInformation} from '../types';
import MarkerWithDayNumber from './MarkerWithDayNumber';
import {polarToCartesian} from './utils';

/**
 * Draw the current selected day marker if the selected day is passed
 * @param props.trackerInformation
 * @param props.currentDay if not passed, do nothing
 * @param props.markerOptions optional stylization
 * @returns
 */
export default function CurrentDayMarker(props: {
    trackerInformation: TrackerInformation;
    currentDay?: number;
    markerOptions?: CurrentDayMarkerOptions;
}): ReactElement | null {
    const [position, setPosition] = useState<Position | undefined>(undefined);
    const radius = 40;
    const primaryColor = '#00B4FB';
    const primaryTextColor = 'white';
    const opcacity = '0.25';
    const borderWidth = 3;
    const strokeLinecap = 'round';
    const strokeDasharray = `0, 8`;

    useEffect(() => {
        if (props.currentDay !== undefined) {
            if (
                props.markerOptions &&
                props.markerOptions.disableDays &&
                props.markerOptions.disableDays.includes(props.currentDay)
            ) {
                setPosition(undefined);
                return;
            }
            const daySection = props.trackerInformation.degreePerDay;
            const day = props.currentDay - 1;

            setPosition(
                polarToCartesian(
                    {x: 0, y: 0},
                    props.trackerInformation.radius,
                    day === 0 ? 0 : day * daySection - props.trackerInformation.lineCapAngle
                )
            );
        }
    }, [props.currentDay, props.markerOptions, props.trackerInformation]);

    return (
        <>
            {props.currentDay && position && (
                <MarkerWithDayNumber
                    posX={position.x}
                    posY={position.y}
                    text={String(props.currentDay)}
                    circleProps={{
                        r: radius,
                        fill: props.markerOptions?.strokeColor || primaryColor,
                        fillOpacity: props.markerOptions?.opacity || opcacity
                    }}
                    borderProps={{
                        r: radius - 1,
                        stroke: props.markerOptions?.borderColor || primaryColor,
                        strokeWidth: props.markerOptions?.borderWidth || borderWidth,
                        strokeLinecap: props.markerOptions?.strokeLinecap || strokeLinecap,
                        strokeDasharray: props.markerOptions?.strokeDasharray || strokeDasharray
                    }}
                    textProps={{
                        fill: props.markerOptions?.textColor || primaryTextColor
                    }}
                />
            )}
        </>
    );
}
