import {
    getObjectByIdHash,
    storeVersionedObject
} from '@refinio/one.core/lib/storage-versioned-objects';
import {OEvent} from 'one.models/lib/misc/OEvent';
import {ChannelManager, InstancesModel, LeuteModel} from 'one.models/lib/models';
import {SingleUser} from 'one.models/lib/models/Authenticator';
import RecipesExperimental from 'one.models/lib/recipes/recipes-experimental';
import RecipesStable from 'one.models/lib/recipes/recipes-stable';
import ReverseMapsExperimental from 'one.models/lib/recipes/reversemaps-experimental';
import ReverseMapsStable from 'one.models/lib/recipes/reversemaps-stable';
import AppRecipes, { isDateTime } from './recipes';
import type {Cycle, AppDataTypes, Expanded, MeasurementTypes} from './recipes';
import { getObject, storeUnversionedObject } from '@refinio/one.core/lib/storage-unversioned-objects';

export default class Model {
    public onOneModelsReady = new OEvent<() => void>();
    public onNewCycle = new OEvent<() => void>();
    // TODO: Should I have one event per measurement type?
    public onNewMeasurement = new OEvent<(measurementType: string) => void>();

    public one: SingleUser;
    public channelManager: ChannelManager;
    public instancesModel: InstancesModel;
    public leuteModel: LeuteModel;

    private enabledMeasurementTypes: string[] = ['MeasurementBleeding', 'MeasurementAbdominalPain'];

    constructor() {
        this.instancesModel = new InstancesModel();
        this.channelManager = new ChannelManager();

        this.one = new SingleUser({
            recipes: [...RecipesStable, ...RecipesExperimental, ...AppRecipes],
            reverseMaps: new Map([...ReverseMapsStable, ...ReverseMapsExperimental])
        });
        this.leuteModel = new LeuteModel(this.instancesModel, 'no-comm-server');

        // Setup event handler that initialize the models when somebody logged in
        // and shuts down the model when somebody logs out.
        this.one.onLogin(this.init.bind(this));
        this.one.onLogout(this.shutdown.bind(this));
    }

    public async init(instanceName: string, secret: string) {
        console.log('Init Triggered');
        try {
            await this.instancesModel.init(secret);
            await this.channelManager.init();
            await this.leuteModel.init();
        } catch (e) {
            await this.shutdown().catch(console.error);
            throw e;
        }

        // We create one channel per data type
        // This means we can later query the data from the respective channels without worrying about the datatypes
        // TODO: If it turns out that we always want to query multiple measurement types at the same time, this might be a bad architecture
        await this.channelManager.createChannel('Cycle');
        for (const measurementType of this.enabledMeasurementTypes) {
            await this.channelManager.createChannel(measurementType);
        }
        this.channelManager.onUpdated.listen((channelId, data) => {
            if (channelId === 'Cycle') {
                this.onNewCycle.emit();
            } else if (this.enabledMeasurementTypes.includes(channelId)) {
                this.onNewMeasurement.emit(channelId);
            }
        });


        this.onOneModelsReady.emit();
    }

    private async getValues<T extends keyof AppDataTypes>(measurementType: T): Promise<AppDataTypes[T][]> {
        const wrapperResults  = await this.channelManager.getObjectsWithType("Wrapper", {
            channelId: measurementType
        });
        const objects = await Promise.all(
            wrapperResults.map(async wrapper => (await getObjectByIdHash(wrapper.data.data)).obj)
        );
        // TODO: That is not that nice typing wise
        return (objects as AppDataTypes[T][]);
    }


    public async getCycles(): Promise<Cycle[]> {
        return this.getValues('Cycle');
    }

    // TODO: For some reason eslint does not like my overload.
    // public async getMeasurements<T extends keyof MeasurementTypes>(measurementType: T, expand: true):  Promise<Expanded<MeasurementTypes[T]>[]>;
    // public async getMeasurements<T extends keyof MeasurementTypes>(measurementType: T, expand: false):  Promise<MeasurementTypes[T][]>;
    public async getMeasurements<T extends keyof MeasurementTypes>(measurementType: T, expand: boolean = true): Promise<Expanded<MeasurementTypes[T]>[] | MeasurementTypes[T][]> {
        let value = await this.getValues(measurementType);
        if (expand) {
            return await Promise.all(value.map(async obj => {
                return {...obj, entered_at: (await getObject(obj.entered_at))};
            }));
        }
        return value
    }

    public async addCycle(obj: Cycle): Promise<void> {
        const cycleHash = (await storeVersionedObject(obj)).idHash;
        await this.channelManager.postToChannelIfNotExist('Cycle', {
            $type$: 'Wrapper',
            data: cycleHash
        });
    }

    public async addMeasurement<T extends keyof MeasurementTypes>(obj: MeasurementTypes[T] | Expanded<MeasurementTypes[T]>): Promise<void> {
        if (isDateTime(obj.entered_at)) {
            // Expanded object
            obj = {...obj, entered_at: (await storeUnversionedObject(obj.entered_at)).hash};
        }
        obj = (obj as MeasurementTypes[T])
        const measurementHash = (await storeVersionedObject(obj)).idHash;
        await this.channelManager.postToChannelIfNotExist(obj.$type$, {
            $type$: 'Wrapper',
            data: measurementHash
        });
    }

    // Setup shutdown
    public async shutdown(): Promise<void> {
        try {
            await this.channelManager.shutdown();
        } catch (e) {
            console.error(e);
        }
        try {
            await this.instancesModel.shutdown();
        } catch (e) {
            console.error(e);
        }
        try {
            await this.leuteModel.shutdown();
        } catch (e) {
            console.error(e);
        }
    }
}
