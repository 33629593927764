import {useState, useEffect} from 'react';
import type {SingleUser} from 'one.models/lib/models/Authenticator';
import type {AuthState} from 'one.models/lib/models/Authenticator/Authenticator';

/**
 * Provides the current authentication state from the SingleUser
 *
 * @param one - The one instance model
 * @returns the current authentication state
 */
export function useAuthenticationState(one: SingleUser): AuthState | undefined {
    const [authenticationState, setAuthenticationState] = useState<AuthState>();

    useEffect(() => {
        function fetchCurrentAuthenticationState(): void {
            setAuthenticationState(one.authState.currentState);
        }

        const disconnectOnAuthStateChanged = one.authState.onEnterState(
            fetchCurrentAuthenticationState
        );
        fetchCurrentAuthenticationState();

        return () => {
            disconnectOnAuthStateChanged();
        };
    }, [one]);

    return authenticationState;
}

export function logout(one: SingleUser): void {
    one.logout().then(() => {
        window.history.pushState({}, '', '/');
    });
}

export function useIsRegistered(one: SingleUser): boolean {
    const [isRegistered, setRegistered] = useState<boolean>(false);

    useEffect(() => {
        let ignore = false;
        function fetchCurrentAuthenticationState(): void {
            if (!ignore) {
                one.isRegistered()
                    .then(res => {
                        setRegistered(res);
                    })
                    .catch(_ => setRegistered(false));
            }
        }

        fetchCurrentAuthenticationState();

        return () => {
            ignore = true;
        };
    }, [one, one.authState.currentState]);

    return isRegistered;
}
