import type {ReactElement} from 'react';
import type {ArcAddition, TrackerInformation} from '../types';
import RoundedArc from './RoundedArc';

/**
 * Draws the remaining ending arc of the main circle, if not finished already (by not passing ArcAdditions)
 * @param {TrackerInformation} props.trackerInformation
 * @param {ArcAddition} [props.arcAddition]
 * @returns
 */
export default function CircleBorderArcEndAddition(props: {
    trackerInformation: TrackerInformation;
    arcAddition?: ArcAddition;
}): ReactElement {
    return (
        <>
            {props.arcAddition && (
                <RoundedArc
                    x={0}
                    y={0}
                    radius={props.trackerInformation.radius}
                    startAngle={
                        359 - (props.trackerInformation.degreePerDay + props.arcAddition.angle)
                    }
                    endAngle={
                        360 -
                        (5 +
                            props.trackerInformation.sliderDayJumpDegree +
                            props.trackerInformation.lineCapAngle)
                    }
                    strokeWidth={
                        props.arcAddition.strokeWidth || props.trackerInformation.circleStrokeWidth
                    }
                    strokeColor={props.arcAddition.color}
                    strokeDasharray={props.arcAddition.strokeDasharray}
                />
            )}
        </>
    );
}
