import type {ReactElement} from 'react';
import './MarkdownPopup.css';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import MarkdownPaperView from './MarkdownPaperView';
import {IconButton} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useTheme} from '@mui/material/styles';

/**
 * The markdown dialog component.
 * @param props
 * @param props.filePath - the filePath of the markdown file that will be displayed.
 * @param props.setFilePath - a callback to reset the state that triggers the render of this component
 * => helps to prevent the bug when you can not open anymore the closed md file.
 * @returns
 */
export default function MarkdownPopup(props: {
    filePath: string;
    setFilePath: (value: string) => void;
}): ReactElement {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    /**
     * Used to close the dialog when "x" button is pressed/clicking outside of the popup.
     */
    function closeDialog(): void {
        props.setFilePath('');
    }

    return (
        <Dialog
            fullScreen={fullScreen}
            open={props.filePath !== ''}
            onClose={closeDialog}
            className="md-dialog"
        >
            <div>
                <DialogTitle className="md-dialog-title">
                    <IconButton className="md-dialog-close-button" onClick={closeDialog}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
            </div>
            <DialogContent>
                <MarkdownPaperView filePath={props.filePath} />
            </DialogContent>
        </Dialog>
    );
}
