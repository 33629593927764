import type {ReactElement} from 'react';
import type {InnerIndicator, TrackerInformation} from '../types';
import RoundedArc from './RoundedArc';

export default function Indicators(props: {
    trackerInformation: TrackerInformation;
    indicators?: InnerIndicator[];
}): ReactElement {
    if (props.indicators === undefined) {
        return <></>;
    }
    return (
        <>
            {props.indicators.map((indicator, indicatorKey) => {
                const color = indicator.color ? indicator.color : '#EA3C53';
                const startAngle = (indicator.day - 1) * props.trackerInformation.degreePerDay;
                return (
                    <RoundedArc
                        x={0}
                        y={0}
                        radius={
                            props.trackerInformation.radius -
                            props.trackerInformation.circleStrokeWidth
                        }
                        startAngle={startAngle}
                        endAngle={startAngle}
                        strokeWidth={props.trackerInformation.circleStrokeWidth / 4}
                        strokeColor={color}
                        key={`indicator-${indicatorKey}`}
                    />
                );
            })}
        </>
    );
}
