import type {ReactElement} from 'react';
import {useEffect, useState} from 'react';
import MarkerWithDayNumber from './MarkerWithDayNumber';
import type {SelectedDayMarkerOptions, TrackerInformation} from '../types';
import {polarToCartesian} from './utils';
import type {Position} from '../../../types/svg';

/**
 * Draw the current current day marker
 * @param props.selectedDay
 * @param props.trackerInformation
 * @param props.selectedDayMarkerOptions optional stylization
 * @returns
 */
export default function SelectedDayMarker(props: {
    selectedDay: number | undefined;
    trackerInformation: TrackerInformation;
    markerOptions?: SelectedDayMarkerOptions;
}): ReactElement | null {
    const [position, setPosition] = useState<Position | undefined>(undefined);
    const radius = 40;
    const primaryColor = '#00B4FB';
    const secondaryColor = '#F0F3F5';
    const primaryTextColor = 'white';
    const selectorBorderWidth = 8;

    useEffect(() => {
        if (props.selectedDay) {
            setPosition(
                polarToCartesian(
                    {x: 0, y: 0},
                    props.trackerInformation.radius,
                    props.selectedDay === 1
                        ? 0
                        : (props.selectedDay - 1) * props.trackerInformation.degreePerDay -
                              props.trackerInformation.sliderDayJumpDegree
                )
            );
        }
    }, [props.selectedDay, props.trackerInformation]);

    return (
        <>
            {props.selectedDay && position && (
                <MarkerWithDayNumber
                    posX={position.x}
                    posY={position.y}
                    text={String(props.selectedDay)}
                    circleProps={{
                        r: radius,
                        fill: primaryColor,
                        stroke: primaryColor,
                        strokeWidth: 0
                    }}
                    borderProps={{
                        r: radius - 1,
                        stroke: props.markerOptions?.borderColor || secondaryColor,
                        strokeWidth: props.markerOptions?.borderWidth || selectorBorderWidth
                    }}
                    textProps={{
                        fill: props.markerOptions?.textColor || primaryTextColor
                    }}
                />
            )}
        </>
    );
}
