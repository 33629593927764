import type {ReactElement} from 'react';
import {useEffect} from 'react';
import {useState} from 'react';
import {useRef} from 'react';

import type {
    ArcAddition,
    CurrentDayMarkerOptions,
    InnerIndicator,
    SelectedDayMarkerOptions,
    SVGViewBox,
    TrackerInformation
} from './types';
import type {OnSelectedDayCallback} from './SelectionEventManagement';
import useDaySelectionEventManagement from './SelectionEventManagement';
import CurrentDayMarker from './svgComponents/CurrentDayMarker';
import CircleBorderArc from './svgComponents/CircleBorderArc';
import CircleBorderArcEndAddition from './svgComponents/CircleBorderArcEndAddition';
import SelectedDayMarker from './svgComponents/SelectedDayMarker';
import {useWindowDimensions} from '../../hooks/device/window';
import type {GetInfoCallBack} from './svgComponents/InformationalMiddleText';
import InformationalMiddleText from './svgComponents/InformationalMiddleText';
import './CycleTracker.css';
import CircleBoarderSegmentArcs from './svgComponents/CircleBoarderSegmentArcs';
import Indicators from './svgComponents/Indicators';
import type {SectionParams} from '../../types/svg';

function filterNumber(num: number | undefined, from: number, to: number): number {
    return num && num >= from && num <= to ? num : from;
}

/**
 * @param props.cycleLength
 * @param props.currentDay
 * @param props.selectedDay
 * @param props.disableSelection Default false
 * @param props.segmentSections - marking arc ranges colors, based on days
 * @param props.innerIndicators - borderWidth/4 size circles placed inside the border
 * @param props.color Default #FAD1D6
 * @param props.borderWidth Default 40
 * @param props.elementDistanceDeg Default 3
 * @param props.onSelectedDay
 * @param props.circleEndAddition
 * @param props.selectedDayMarkerOptions
 * @param props.currentDayMarkerOptions
 * @param props.setInfoTextCallback
 * @returns
 */
export default function CycleTracker(props: {
    cycleLength: number;
    currentDay?: number;
    selectedDay?: number;
    disableSelection?: boolean;
    segmentSections?: SectionParams[];
    innerIndicators?: InnerIndicator[];
    color?: string;
    borderWidth?: number;
    elementDistanceDeg?: number;
    onSelectedDay?: OnSelectedDayCallback;
    circleEndAddition?: ArcAddition;
    selectedDayMarkerOptions?: SelectedDayMarkerOptions;
    currentDayMarkerOptions?: CurrentDayMarkerOptions;
    setInfoTextCallback?: GetInfoCallBack;
}): ReactElement {
    const [currentDay, setCurrentDay] = useState<number>(
        filterNumber(props.currentDay, 1, props.cycleLength)
    );
    const [selectedDay, setSelectedDay] = useState<number>(
        filterNumber(props.selectedDay, 1, props.cycleLength)
    );

    // filter invalid or undefined props.currentDay
    useEffect(() => {
        setCurrentDay(filterNumber(props.currentDay, 1, props.cycleLength));
    }, [props.cycleLength, props.currentDay]);

    // filter invalid or undefined props.selectedDay
    useEffect(() => {
        setSelectedDay(filterNumber(props.selectedDay, 1, props.cycleLength));
    }, [props.cycleLength, props.selectedDay]);

    // circle information calculations state
    const [trackerInformation, setTrackerInformation] = useState<TrackerInformation>({
        daysRangeDegrees: 0,
        degreePerDay: 0,
        sliderDayJumpDegree: 0,
        radius: 0,
        lineCapAngle: 0,
        circleStrokeWidth: 0,
        color: '#000',
        middleTextMaxPxFontSize: 20
    });

    // extracted to be in one place
    const svgViewBox: SVGViewBox = {
        xStart: -350,
        yStart: -350,
        xTotal: 700,
        yTotal: 700
    };

    // circle information calculations
    const [windowWidth, windowHeight] = useWindowDimensions();
    useEffect(() => {
        // default values or props
        const circleStrokeWidth = props.borderWidth ? props.borderWidth : 40;
        const color = props.color ? props.color : '#FAD1D6';
        const elementDistanceDeg = props.elementDistanceDeg ? props.elementDistanceDeg : 3;
        const daysRangeDegrees = props.circleEndAddition
            ? 360 - props.circleEndAddition.angle
            : 360;

        const radius = svgViewBox.xTotal / 2 - 50;
        // We need to correct the distance by calculating the angle occupied by the roudn linecap with has the length of half the stroke width
        const lineCapAngle = (Math.asin(circleStrokeWidth / 2 / radius) * 180) / Math.PI;
        const sliderDayJumpDegree = elementDistanceDeg / 2;
        const degreePerDay = daysRangeDegrees / props.cycleLength;

        setTrackerInformation({
            daysRangeDegrees: daysRangeDegrees,
            degreePerDay: degreePerDay,
            sliderDayJumpDegree: sliderDayJumpDegree,
            radius,
            lineCapAngle,
            circleStrokeWidth,
            color,
            middleTextMaxPxFontSize: 20
        });
    }, [props, svgViewBox.xTotal, windowHeight, windowWidth]);

    // selection even management
    const containerRef = useRef<HTMLDivElement>(null);
    const {selectingDay, currentlySelecting} = useDaySelectionEventManagement(
        selectedDay,
        !!props.disableSelection,
        containerRef,
        props.cycleLength,
        trackerInformation.degreePerDay,
        svgViewBox,
        props.onSelectedDay
    );

    return (
        <div
            className="cycle-tracker-container"
            style={{
                cursor: currentlySelecting ? 'grabbing' : 'grab'
            }}
            ref={containerRef}
        >
            <svg
                className="cycle-traker"
                preserveAspectRatio="xMidYMid meet"
                style={{width: 'inherit', height: 'inherit'}}
                viewBox={`${svgViewBox.xStart} ${svgViewBox.yStart} ${svgViewBox.xTotal} ${svgViewBox.yTotal}`}
            >
                {/* Draw Tracker main circle border arc */}
                <CircleBorderArc
                    trackerInformation={trackerInformation}
                    trackerAngleEnd={
                        props.circleEndAddition ? props.circleEndAddition.angle : undefined
                    }
                />

                {/* Draw Tracker end circle */}
                <CircleBorderArcEndAddition
                    trackerInformation={trackerInformation}
                    arcAddition={props.circleEndAddition}
                />

                {/* Draw Tracker sections border arcs at specific days */}
                <CircleBoarderSegmentArcs
                    segmentSections={props.segmentSections}
                    trackerInformation={trackerInformation}
                />

                {/* Draw Tracker sections border arcs at specific days */}
                <Indicators
                    indicators={props.innerIndicators}
                    trackerInformation={trackerInformation}
                />

                {/* Draw progress day marker */}
                <CurrentDayMarker
                    currentDay={selectingDay === currentDay ? undefined : currentDay}
                    markerOptions={props.currentDayMarkerOptions}
                    trackerInformation={trackerInformation}
                />

                {/* Draw selected day marker */}
                <SelectedDayMarker
                    trackerInformation={trackerInformation}
                    markerOptions={props.selectedDayMarkerOptions}
                    selectedDay={selectingDay}
                />

                {/* Draw information text */}
                <InformationalMiddleText
                    currentDay={currentDay}
                    trackerInformation={trackerInformation}
                    getTextsCallback={props.setInfoTextCallback}
                />
            </svg>
        </div>
    );
}
