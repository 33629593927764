import type {ReactElement} from 'react';
import type {ArcParams} from '../types';
import {describeArc} from './utils';

// eslint-disable-next-line no-undef
export default function RoundedArc(props: ArcParams): ReactElement {
    return (
        <path
            d={describeArc(
                {x: props.x, y: props.y},
                props.radius,
                props.startAngle,
                props.endAngle
            )}
            fill="none"
            stroke={props.strokeColor}
            strokeWidth={props.strokeWidth}
            strokeLinecap="round"
            id={props.id}
            strokeDasharray={props.strokeDasharray}
        />
    );
}
