import type {ReactElement} from 'react';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import './InstancesSettingsView.css';
import type {InstancesModel} from 'one.models/lib/models';
import type {SHA256IdHash} from '@refinio/one.core/lib/util/type-checks';
import type {Instance, Person} from '@refinio/one.core/lib/recipes';
import {useNavigate} from 'react-router-dom';
import {Button, Typography} from '@mui/material';
import DeleteInstanceModal from './DeleteInstanceModal';
import type {SingleUser} from 'one.models/lib/models/Authenticator';
import {NOTIFICATION, useNotificationContext} from '../components/SnackbarNotification';
import CustomCard from '../components/CustomCard';

type MainInstanceDetails = {
    id: SHA256IdHash<Instance>;
    name: string;
    owner: SHA256IdHash<Person>;
};

/**
 *  Detailed View for Settings
 */
export default function InstancesSettingsView(props: {
    instancesModel: InstancesModel;
    one: SingleUser;
}): ReactElement {
    const {i18n} = useTranslation();

    const {setNotificationMessage, setNotificationType} = useNotificationContext();

    const [mainInstanceDetailsInformation, setMainInstanceDetailsInformation] =
        useState<MainInstanceDetails>();

    const [deleteInstanceModal, setDeleteInstanceModal] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        async function fetchMainInstanceSettingsDetails(): Promise<void> {
            const mainInstance = await props.instancesModel.mainInstance();
            setMainInstanceDetailsInformation({
                id: await props.instancesModel.mainInstanceId(),
                name: mainInstance.name,
                owner: mainInstance.owner
            });
        }

        fetchMainInstanceSettingsDetails().catch(() => {
            setNotificationType(NOTIFICATION.Error);
            setNotificationMessage('common:errors.settings');
        });
    }, [props.instancesModel, setNotificationMessage, setNotificationType]);

    function onDeleteInstanceButtonPress() {
        setDeleteInstanceModal(true);
    }

    function closeDeleteInstanceModal() {
        setDeleteInstanceModal(false);
    }

    async function handleDeleteInstance(): Promise<void> {
        navigate('/', {replace: true});
        try {
            await props.one.logoutAndErase();
        } catch (e) {
            if (
                e
                    .toString()
                    .includes(
                        "InvalidStateError: Failed to read the 'result' property from 'IDBRequest': The request has not finished."
                    )
            ) {
                props.one.authState.triggerEvent('logout_done');
            } else throw e;
        } finally {
            setNotificationType(NOTIFICATION.Success);
            setNotificationMessage('settings:deleteModal.onDeleteNotification');
        }
    }

    function instanceCardContent(): ReactElement {
        return (
            <>
                {mainInstanceDetailsInformation !== undefined && (
                    <>
                        <Typography sx={{fontSize: 12}} color="text.secondary">
                            {i18n.t('settings:instanceOwner')}
                        </Typography>
                        <Typography
                            className={'instance-setting-item-margin'}
                            variant="body1"
                            component="p"
                        >
                            {mainInstanceDetailsInformation.owner}
                        </Typography>
                        <Typography sx={{fontSize: 12}} color="text.secondary">
                            {i18n.t('settings:instanceName')}
                        </Typography>
                        <Typography
                            className={'instance-setting-item-margin'}
                            variant="body1"
                            component="div"
                        >
                            {mainInstanceDetailsInformation.name}
                        </Typography>
                        <Typography sx={{fontSize: 12}} color="text.secondary">
                            {i18n.t('settings:instanceId')}
                        </Typography>
                        <Typography
                            className={'instance-setting-item-margin'}
                            variant="body1"
                            component="div"
                        >
                            {mainInstanceDetailsInformation.id}
                        </Typography>

                        <Typography sx={{fontSize: 12}} color="text.secondary">
                            {i18n.t('settings:gitHash')}
                        </Typography>

                        <Typography
                            className={'instance-setting-item-margin'}
                            variant="body1"
                            component="div"
                        >
                            {'3f3708029fac1cb81c39569396d55d3c95477c15'}
                        </Typography>
                    </>
                )}
            </>
        );
    }

    function instanceCardActions(): ReactElement {
        return (
            <>
                {mainInstanceDetailsInformation !== undefined && (
                    <Button
                        onClick={onDeleteInstanceButtonPress}
                        color="error"
                        className={'delete-instance-button'}
                        variant="outlined"
                        size="small"
                    >
                        {i18n.t('settings:deleteModal.delete')}
                    </Button>
                )}
            </>
        );
    }

    return (
        <div className="instance-settings-wrapper">
            <DeleteInstanceModal
                open={deleteInstanceModal}
                handleDelete={handleDeleteInstance}
                handleClose={closeDeleteInstanceModal}
            />
            <div className="view-title">{i18n.t('settings:instance')}</div>
            <CustomCard
                content={instanceCardContent()}
                action={instanceCardActions()}
                className="instanceCard"
            />
        </div>
    );
}
