import type {ReactElement} from 'react';
import type {CycleBarInformation} from '../types';
import MarkerWithDayNumber from './MarkerWithDayNumber';

export default function Marker(props: {
    currentDay: number | undefined;
    cycleBarInformation: CycleBarInformation;
}): ReactElement {
    if (props.currentDay === undefined) {
        return <></>;
    }

    const position = {
        x: props.currentDay * props.cycleBarInformation.dayWidth,
        y:
            props.cycleBarInformation.start.y +
            (props.cycleBarInformation.end.y - props.cycleBarInformation.start.y) / 2
    };

    return (
        <MarkerWithDayNumber
            posX={position.x}
            posY={position.y}
            text={String(props.currentDay)}
            circleProps={{
                r: props.cycleBarInformation.markerRadius,
                fill: props.cycleBarInformation.markerColor,
                stroke: props.cycleBarInformation.markerColor,
                strokeWidth: 0
            }}
            borderProps={{
                r:
                    props.cycleBarInformation.markerRadius > 0
                        ? props.cycleBarInformation.markerRadius - 1
                        : 0,
                stroke: props.cycleBarInformation.markerBorderColor,
                strokeWidth: 8
            }}
            textProps={{
                fill: props.cycleBarInformation.markerTextColor
            }}
        />
    );
}
