import type React from 'react';
import {useEffect, useState} from 'react';

import type {SVGViewBox, UseDaySelectionResult} from './types';

/**
 * if returned false, the selector will remain in place
 */
export type OnSelectedDayCallback = (day: number) => boolean | void;

export default function useDaySelectionEventManagement(
    defaultSelectedDay: number | undefined,
    disableSelection: boolean,
    containerRef: React.RefObject<HTMLDivElement>,
    numberOfDays: number,
    degreePerDay: number,
    svgViewBox: SVGViewBox,
    onSelectedDay?: OnSelectedDayCallback
): UseDaySelectionResult {
    let [selectedDay, setSelectedDay] = useState<number | undefined>(defaultSelectedDay);
    let [currentlySelecting, setCurrentlySelecting] = useState(false);

    useEffect(() => {
        setSelectedDay(defaultSelectedDay);
    }, [defaultSelectedDay]);

    // attach events
    useEffect(() => {
        if (containerRef.current === null || !!disableSelection) {
            return;
        }

        const container = containerRef.current;

        function getSelectedDay(domX: number, domY: number, rect: DOMRect) {
            let x = ((domX - rect.left) / rect.width) * svgViewBox.xTotal + svgViewBox.xStart;
            let y = ((domY - rect.top) / rect.height) * svgViewBox.yTotal + svgViewBox.yStart;

            // The circle is in the center (0,0) which makes this simple
            let angleOfClick = (Math.atan2(x, -y) * 180) / Math.PI;
            if (angleOfClick < -(degreePerDay / 2)) {
                angleOfClick += 360;
            }
            let dayOfClick = Math.round(angleOfClick / degreePerDay) + 1;
            if (dayOfClick > numberOfDays) {
                dayOfClick = numberOfDays;
            }
            return dayOfClick;
        }

        function onStart(x: number, y: number) {
            setCurrentlySelecting(true);
            const daySelected = getSelectedDay(x, y, container.getBoundingClientRect());
            setSelectedDay(daySelected);
        }

        function onMove(x: number, y: number) {
            const daySelected = getSelectedDay(x, y, container.getBoundingClientRect());
            setSelectedDay(daySelected);
        }

        function onEnd(x: number, y: number) {
            const daySelected = getSelectedDay(x, y, container.getBoundingClientRect());
            let deselect: boolean | void = true;
            if (onSelectedDay) {
                deselect = onSelectedDay(daySelected);
            }
            if (deselect === undefined || !!deselect) {
                setSelectedDay(defaultSelectedDay);
            }
            setCurrentlySelecting(false);
        }

        // Handle touch
        function onTouchStart(e: TouchEvent) {
            const touch = e.touches[0];
            onStart(touch.clientX, touch.clientY);
            e.stopPropagation();
            e.preventDefault();
        }

        function onTouchEnd(e: TouchEvent) {
            onEnd(e.changedTouches[0].clientX, e.changedTouches[0].clientY);
            e.stopPropagation();
            e.preventDefault();
        }

        function onTouchMove(e: TouchEvent) {
            if (!currentlySelecting) {
                return;
            }
            const touch = e.touches[0];
            onMove(touch.clientX, touch.clientY);
            e.stopPropagation();
            e.preventDefault();
        }

        // Handle mouse
        function onMouseDown(e: MouseEvent) {
            onStart(e.clientX, e.clientY);
            e.stopPropagation();
            e.preventDefault();
        }

        function onMouseUp(e: MouseEvent) {
            onEnd(e.clientX, e.clientY);
            e.stopPropagation();
            e.preventDefault();
        }

        function onMouseMove(e: MouseEvent) {
            if (!currentlySelecting) {
                return;
            }
            onMove(e.clientX, e.clientY);
            e.stopPropagation();
            e.preventDefault();
        }

        container.addEventListener('mousedown', onMouseDown, {passive: false});
        container.addEventListener('mouseup', onMouseUp, {passive: false});
        container.addEventListener('mousemove', onMouseMove, {passive: false});

        container.addEventListener('touchstart', onTouchStart, {passive: false});
        container.addEventListener('touchmove', onTouchMove, {passive: false});
        container.addEventListener('touchend', onTouchEnd, {passive: false});
        container.addEventListener('touchcancel', onTouchEnd, {passive: false});

        return () => {
            container.removeEventListener('mousedown', onMouseDown);
            container.removeEventListener('mouseup', onMouseUp);
            container.removeEventListener('mousemove', onMouseMove);

            container.removeEventListener('touchstart', onTouchStart);
            container.removeEventListener('touchmove', onTouchMove);
            container.removeEventListener('touchend', onTouchEnd);
            container.removeEventListener('touchcancel', onTouchEnd);
        };
    }, [
        containerRef,
        currentlySelecting,
        numberOfDays,
        svgViewBox,
        degreePerDay,
        disableSelection,
        onSelectedDay,
        defaultSelectedDay
    ]);

    if (containerRef.current === null || !!disableSelection) {
        return {
            selectingDay: defaultSelectedDay,
            currentlySelecting: false
        };
    }

    return {
        selectingDay: selectedDay,
        currentlySelecting
    };
}
