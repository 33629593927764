import type {ReactElement} from 'react';
import {useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import * as React from 'react';
import {Button, TextField} from '@mui/material';

/* eslint import/no-webpack-loader-syntax: off */
// workaround which uses the fact that webpack is used under the hood
// source: https://github.com/facebook/create-react-app/issues/11770#issuecomment-1022024494
import Password from '!file-loader!./../resources/svgs/password.svg';

import type {SingleUser} from 'one.models/lib/models/Authenticator';

import Footer from './components/Footer';
import {NOTIFICATION, useNotificationContext} from './components/SnackbarNotification';
import {onEnterPress} from './Utils';

import './Login.css';

export default function Login(props: {one: SingleUser}): React.ReactElement {
    const [password, setPassword] = useState('');
    const isUnlockButtonDisabled = password === '';
    const {setNotificationMessage, setNotificationType} = useNotificationContext();
    const buttonRef = useRef<HTMLButtonElement>(null);
    const i18n = useTranslation();

    function login(): void {
        props.one.login(password).catch(() => {
            setNotificationMessage('common:errors.wrongPassword');
            setNotificationType(NOTIFICATION.Error);
        });
    }

    function loginView(): ReactElement {
        return (
            <div className="blut-login">
                <div className="blut-welcome-image">
                    <img src={Password} alt={i18n.t('blut:password')} />
                </div>
                <div className="login-content">
                    <div className="login-title">{i18n.t('blut:welcome')}</div>
                    <div className="blut-information body">{i18n.t('blut:login.information')}</div>
                    <form className="password-form">
                        <TextField
                            className="text-field-entry"
                            value={password}
                            label={i18n.t('blut:password')}
                            variant="standard"
                            type="password"
                            fullWidth={true}
                            onChange={event => setPassword(event.target.value)}
                            InputProps={{autoComplete: 'on'}}
                            onKeyPress={e => {
                                onEnterPress(e, () => {
                                    if (buttonRef && buttonRef.current) {
                                        buttonRef.current.click();
                                    }
                                });
                            }}
                        />
                    </form>
                </div>
                <Button
                    ref={buttonRef}
                    color="primary"
                    variant="text"
                    className={`big-rounded blut-btn blue-bg ${
                        isUnlockButtonDisabled ? 'disabled' : ''
                    }`}
                    disabled={isUnlockButtonDisabled}
                    onClick={login}
                >
                    {i18n.t('blut:login.unlock')}
                </Button>
                <Footer />
            </div>
        );
    }

    return <>{loginView()}</>;
}
