import type {ReactElement} from 'react';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import deLocale from 'date-fns/locale/de';
import type Locale from 'date-fns';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import {IconButton, TextField} from '@mui/material';
import {MobileDatePicker} from '@mui/lab';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import './DatePicker.css';

export function DatePicker(props: {
    onChange?: (date: Date) => void;
    initialDate?: Date;
    maxDate?: Date;
    minDate?: Date;
    disableChange?: boolean;
    getLocale?: (language: string) => Locale;
}): ReactElement {
    const initialDate = props.initialDate === undefined ? new Date(Date.now()) : props.initialDate;
    const [value, setValue] = useState<Date>(initialDate);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const i18n = useTranslation();
    const [language, setLanguage] = useState<string>(i18n.i18n.language);

    useEffect(() => {
        const newInitialDate =
            props.initialDate === undefined ? new Date(Date.now()) : props.initialDate;
        setValue(newInitialDate);
    }, [props.initialDate]);

    useEffect(() => {
        setLanguage(i18n.i18n.language);
    }, [i18n.i18n.language]);

    const handleChange = (newValue: Date | null): void => {
        if (!newValue) {
            setValue(initialDate);
            if (props.onChange) {
                props.onChange(initialDate);
            }
        } else {
            setValue(newValue);
            if (props.onChange) {
                props.onChange(newValue);
            }
        }
    };

    return (
        <LocalizationProvider
            dateAdapter={AdapterDateFns}
            locale={props.getLocale ? props.getLocale(language) : deLocale}
        >
            <div className="datepicker datepicker-border-bottom">
                <div className="datepicker-top">
                    <div className="datepicker-row">
                        <div className="datepicker-left">
                            <div className="datepicker-column">
                                <div
                                    className={`datepicker-top bold${
                                        props.disableChange ? ' non-pointer' : ' pointer'
                                    }`}
                                    onClick={() => {
                                        setIsOpen(!props.disableChange);
                                    }}
                                >
                                    {i18n.t(`datepicker:date.dayOfWeek${value.getDay()}`)}
                                </div>
                                <div
                                    className={`datepicker-bottom ${
                                        props.disableChange ? ' input-non-pointer' : ''
                                    }`}
                                >
                                    <MobileDatePicker
                                        okText={i18n.t('datepicker:buttons.ok')}
                                        clearText={i18n.t('datepicker:buttons.clear')}
                                        cancelText={i18n.t('datepicker:buttons.cancel')}
                                        toolbarTitle={i18n.t('datepicker:selectDate')}
                                        leftArrowButtonText={i18n.t('datepicker:previousMonth')}
                                        rightArrowButtonText={i18n.t('datepicker:nextMonth')}
                                        disableCloseOnSelect={true}
                                        maxDate={props.maxDate}
                                        minDate={props.minDate}
                                        readOnly={!!props.disableChange}
                                        open={isOpen}
                                        onOpen={() => {
                                            setIsOpen(true);
                                        }}
                                        onClose={(...args) => {
                                            setIsOpen(false);
                                        }}
                                        value={value}
                                        onChange={handleChange}
                                        renderInput={params => <TextField {...params} />}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="datepicker-right">
                            <div className="datepicker-column">
                                <div
                                    className={`datepicker-bottom${
                                        props.disableChange ? ' button-non-pointer' : ' pointer'
                                    }`}
                                    onClick={() => {
                                        setIsOpen(!props.disableChange);
                                    }}
                                >
                                    <IconButton>
                                        <CalendarTodayIcon />
                                    </IconButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </LocalizationProvider>
    );
}
