import type {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {Button} from '@mui/material';

/* eslint import/no-webpack-loader-syntax: off */
// workaround which uses the fact that webpack is used under the hood
// source: https://github.com/facebook/create-react-app/issues/11770#issuecomment-1022024494
import Welcome from '!file-loader!./../../resources/svgs/welcome.svg';

import Footer from '../components/Footer';
import './Registration.css';

export default function WelcomePage(props: {onDone: () => void}): ReactElement {
    const i18n = useTranslation();

    return (
        <div className="blut-welcome">
            <div className="blut-welcome-image">
                <img src={Welcome} alt={i18n.t('blut:welcome')} />
            </div>
            <div className="blut-page-content text-centered content-centered">
                <div className="blut-information title">{i18n.t('blut:welcome')}</div>
                <div className="blut-information centered">{i18n.t('blut:description')}</div>
                <div className="blut-information centered">{i18n.t('blut:information1')}</div>
                <div className="blut-information centered">{i18n.t('blut:information2')}</div>
                <div className="blut-information centered">{i18n.t('blut:wale')}</div>
            </div>
            <Button
                color="primary"
                variant="text"
                className="big-rounded blut-btn blue-bg"
                onClick={props.onDone}
            >
                {i18n.t('blut:participate')}
            </Button>
            <Footer />
        </div>
    );
}
