import type {ReactElement} from 'react';
import {useEffect, useRef, useState} from 'react';
import {useWindowWidth} from '../../hooks/device/window';

import type {CycleBarInformation} from './types';
import {MainBar} from './svgComponents/MainBar';

import './CycleBar.css';
import type {SectionParams} from '../../types/svg';
import SegmentedSections from './svgComponents/SegmentedSections';
import Marker from './svgComponents/Marker';

const DEFAULTS = {
    color: '#FAD1D6',
    strokeWidth: 20,
    markerColor: '#00B4FB',
    markerBorderColor: '#F0F3F5',
    markerTextColor: 'white'
};

const screenWidthInDays = 32;

/**
 * @param {number} props.cycleLength in days
 * @param {number} [props.currentDay] for current day marker, not rendered if undefined
 * @param {SectionParams[]} [props.segmentSections] not rendered in undefined
 * @param {string} [props.color=DEFAULTS.color]
 * @param {number} [props.strokeWidth=DEFAULTS.strokeWidth]
 * @returns
 */
export default function CycleBar(props: {
    cycleLength: number;
    currentDay?: number;
    segmentSections?: SectionParams[];
    color?: string;
    strokeWidth?: number;
}): ReactElement {
    const [cycleBarInformation, setCycleBarInformation] = useState<CycleBarInformation>({
        width: 0,
        dayWidth: 0,
        strokeWidth: DEFAULTS.strokeWidth,
        color: DEFAULTS.color,
        start: {x: 0, y: 0},
        end: {x: 0, y: 0},
        viewBox: {x: 0, y: 0},
        markerRadius: 0,
        markerColor: '',
        markerBorderColor: '',
        markerTextColor: ''
    });

    const windowWidth = useWindowWidth();
    const container = useRef<HTMLDivElement>(null);

    // on window resize or props change
    useEffect(() => {
        // Cycle bar position in svg is based on current height and width
        // of the container to get responsive design
        if (container.current === null) {
            return;
        }

        const maxWidth = container.current.clientWidth;
        const maxHeight = container.current.clientHeight;
        const dayWidth = Math.floor(maxWidth / screenWidthInDays);
        const width = dayWidth * props.cycleLength;
        const strokeWidth = props.strokeWidth ? props.strokeWidth : DEFAULTS.strokeWidth;
        const lineCapRadius = strokeWidth / 2;
        const paddingLeftAndRigth = lineCapRadius * 2;
        const paddingTopAndBottom = maxHeight - lineCapRadius * 2;

        const viewBox = {
            x: screenWidthInDays * dayWidth,
            y: strokeWidth + paddingTopAndBottom
        };

        if (screenWidthInDays < props.cycleLength) {
            viewBox.x = props.cycleLength * dayWidth + paddingLeftAndRigth / 2;
        }

        setCycleBarInformation({
            width,
            dayWidth,
            strokeWidth,
            color: props.color ? props.color : DEFAULTS.color,
            start: {x: paddingLeftAndRigth / 2, y: strokeWidth / 2 + paddingTopAndBottom / 2},
            end: {x: paddingLeftAndRigth / 2, y: strokeWidth / 2 + paddingTopAndBottom / 2},
            viewBox,
            markerRadius: strokeWidth,
            markerColor: DEFAULTS.markerColor,
            markerBorderColor: DEFAULTS.markerBorderColor,
            markerTextColor: DEFAULTS.markerTextColor
        });
    }, [container, windowWidth, props.cycleLength, props.strokeWidth, props.color]);

    return (
        <div className="cycle-bar-container" ref={container}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox={`0 0 ${cycleBarInformation.viewBox.x} ${cycleBarInformation.viewBox.y}`}
                className="cycle-bar"
                style={{width: `${cycleBarInformation.viewBox.x}px`}}
            >
                {/* Background bar of the component */}
                <MainBar endDay={props.cycleLength} cycleBarInformation={cycleBarInformation} />

                {/* Sections */}
                <SegmentedSections
                    sections={props.segmentSections}
                    cycleBarInformation={cycleBarInformation}
                />

                {/* Marker */}
                <Marker cycleBarInformation={cycleBarInformation} currentDay={props.currentDay} />
            </svg>
        </div>
    );
}
