import type {ReactElement} from 'react';
import RoundedArc from './RoundedArc';
import type {TrackerInformation} from '../types';

/**
 * Svg drawing of circle border arc
 * @param {TrackerInformation} props.trackerInformation
 * @param {number} [props.trackerAngleEnd=0] - stop the border at curtain angle (359 - props.trackerAngleEnd, default 0)
 * @returns
 */
export default function CircleBorderArc(props: {
    trackerInformation: TrackerInformation;
    trackerAngleEnd?: number;
}): ReactElement {
    return (
        <RoundedArc
            x={0}
            y={0}
            radius={props.trackerInformation.radius}
            startAngle={0}
            endAngle={
                359 -
                (props.trackerAngleEnd
                    ? props.trackerAngleEnd + props.trackerInformation.degreePerDay
                    : 0)
            }
            strokeWidth={props.trackerInformation.circleStrokeWidth}
            strokeColor={props.trackerInformation.color}
        />
    );
}
