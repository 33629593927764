import type {ReactElement} from 'react';

import type {SectionParams} from '../../../types/svg';
import type {TrackerInformation} from '../types';
import RoundedArc from './RoundedArc';

/**
 * Draw circle border arcs at specific days
 * @param {TrackerInformation} props.trackerInformation
 * @param {SectionParams[]} [props.markedSections] if missing, do nothing
 * @returns
 */
export default function CircleBoarderSegmentArcs(props: {
    trackerInformation: TrackerInformation;
    segmentSections?: SectionParams[];
}): ReactElement | null {
    return (
        <>
            {props.segmentSections &&
                props.segmentSections.map((section: SectionParams, index: number) => {
                    const startAngle = (section.start - 1) * props.trackerInformation.degreePerDay;
                    const endAngle = (section.end - 1) * props.trackerInformation.degreePerDay;
                    return (
                        <RoundedArc
                            x={0}
                            y={0}
                            radius={props.trackerInformation.radius}
                            startAngle={startAngle}
                            endAngle={endAngle - props.trackerInformation.lineCapAngle}
                            strokeWidth={props.trackerInformation.circleStrokeWidth}
                            strokeColor={section.color ? section.color : 'white'}
                            key={`section-${index}`}
                        />
                    );
                })}
        </>
    );
}
