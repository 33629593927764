import {createContext, useContext} from 'react';

export type MarkdownFilePath = {
    setMarkdownFilePath: (filePath: string) => void;
};

export const MarkdownContext = createContext<MarkdownFilePath>({
    setMarkdownFilePath: () => undefined
});

/**
 * Forward the markdown file path context.
 */
export function useMarkdownContext(): MarkdownFilePath {
    return useContext(MarkdownContext);
}
