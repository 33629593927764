import {Button, Switch} from '@mui/material';
import type {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';

import period from '../../resources/Period.svg';
import spotting from '../../resources/Spotting.svg';
import {getLocale} from '../../i18n';
import type {ButtonSelectOption} from '../components/buttonSelect/ButtonSelect';
import {ButtonSelect} from '../components/buttonSelect/ButtonSelect';
import {DatePicker} from '../components/datepicker/DatePicker';
import {useNavigateBackFunction} from '../hooks/location/history';
import type Model from '../../models/Model';
import './Track.css';
import {useBleedingQuantity} from '../hooks/cycle/cycle';
import i18n from '../../i18n';
import type {MeasurementBleeding} from '../../models/recipes';

const selectButtons: ButtonSelectOption[] = [
    {
        text: i18n.t('track:periodButtons.light'),
        icon: <img className="light-period" alt="light period" src={period} />,
        value: 'light'
    },
    {
        text: i18n.t('track:periodButtons.medium'),
        icon: <img className="medium-period" alt="medium period" src={period} />,
        value: 'medium'
    },
    {
        text: i18n.t('track:periodButtons.heavy'),
        icon: <img className="heavy-period" alt="heavy period" src={period} />,
        value: 'heavy'
    },
    {
        text: i18n.t('track:periodButtons.spotting'),
        icon: <img className="spotting" alt="spotting" src={spotting} />,
        value: 'spotting'
    }
];

function useDateParams(): Date {
    const {day, month, year} = useParams<{day: string; month: string; year: string}>();
    return day && month && year ? new Date(`${month}/${day}/${year}`) : new Date();
}

export default function Track(props: {model: Model}): ReactElement {
    const i18n = useTranslation();
    const goBack = useNavigateBackFunction();
    const date = useDateParams();
    const {quantity, setQuantity} = useBleedingQuantity(date, props.model);

    return (
        <div className="track-container">
            <div className="track-datepicker">
                <DatePicker initialDate={date} getLocale={getLocale} disableChange={true} />
            </div>
            <div className="track-content">
                <div className="track-content-above-bottom">
                    <ButtonSelect
                        className="track-button-select"
                        options={selectButtons}
                        onSelect={value => {
                            setQuantity(value as MeasurementBleeding['value']);
                        }}
                        onDeselect={() => {
                            setQuantity('none');
                        }}
                        enableDeselect={true}
                        selected={quantity}
                    />
                </div>
                <div className="track-content-bottom">
                    <div className="track-content-first-day-container">
                        <div className="track-content-first-day-label">
                            {i18n.t('common:firstDayOfCycleLabel')}
                        </div>
                        <div className="track-content-first-day-switch">
                            <Switch
                                inputProps={{
                                    'aria-label': i18n.t('common:firstDayOfCycleLabel')
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="track-button-container">
                <Button className="big-rounded blue-bg" onClick={goBack}>
                    {i18n.t('common:track')}
                </Button>
            </div>
        </div>
    );
}
