import {createContext} from 'react';
import type {Dispatch, SetStateAction} from 'react';
import type {AppBarMode} from '../appBar/AppBar';
import {APP_BAR_MODE} from '../appBar/AppBar';

/**
 * The structure of the object saved in the context
 */
export type AppBarData = {
    title: string;
    isMenuBtnClicked: boolean;
    isMoreBtnClicked: boolean;
    statusFilters: string[];
    mode: AppBarMode;
    hide?: boolean;
    onDone: () => void;
    onClose: () => void;
};

/**
 * The context structure contains the object and the setter for it
 */
type AppBarContextType = {
    contextValue: AppBarData;
    setContextValue: Dispatch<SetStateAction<AppBarData>>;
};

/**
 * Creates the appBar context
 */
export const AppBarContext = createContext<AppBarContextType>({
    contextValue: {
        title: '',
        isMenuBtnClicked: true,
        isMoreBtnClicked: false,
        statusFilters: [],
        mode: APP_BAR_MODE.BROWSE,
        hide: false,
        onDone: () => {
            return;
        },
        onClose: () => {
            return;
        }
    },
    setContextValue: () => null
});
