import type {KeyboardEvent} from 'react';
import {useContext, useEffect, useLayoutEffect, useRef, useState} from 'react';
import {isEqual, isAfter, isBefore} from 'date-fns';

import {APP_BAR_MODE} from './appBar/AppBar';
import {AppBarContext} from './context/AppBarContext';
import {FILE_FORMATS} from '../constants';

/**
 * Check if a standalone application is used
 */
export function isStandalone(): boolean {
    // ts-ignore needed because window.navigator.standalone is available only for safari
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const isInStandalone = window.navigator.standalone;

    return !!isInStandalone;
}

/**
 * Creates the dynamic manifest, setting the start_url at current window location. (Used for STH)
 *
 * @returns {void}
 */
export function generateDynamicManifest(): void {
    const dynamicManifest = {
        name: 'one.blut',
        short_name: 'one.blut',
        scope: window.location.origin,
        start_url:
            window.location.origin +
            window.location.pathname +
            window.location.search +
            window.location.hash,
        display: 'standalone',
        theme_color: '#000000',
        background_color: '#ffffff',
        icons: [
            {
                src: 'favicon.ico',
                sizes: '64x64 32x32 24x24 16x16',
                type: 'image/x-icon'
            },
            {
                src: 'one.svg',
                type: 'image/png',
                sizes: '192x192'
            },
            {
                src: 'one.svg',
                type: 'image/png',
                sizes: '512x512'
            }
        ]
    };
    const stringManifest = JSON.stringify(dynamicManifest);
    const blob = new Blob([stringManifest], {type: 'application/json'});
    const manifestURL = URL.createObjectURL(blob);
    const htmlTag = document.querySelector('#dynamic-manifest');

    if (htmlTag) {
        htmlTag.setAttribute('href', manifestURL);
    }
}

/**
 * Returns the URL of the image
 * @param arrayBuffer
 * @returns - the URL
 */
export function getURL(arrayBuffer: ArrayBuffer | File | string): string {
    const blob = new Blob([arrayBuffer]);
    return URL.createObjectURL(blob);
}

/**
 * Custom hook for getting the previous value of a state.
 * @param value - the value of a state.
 */
export function usePrevious<T>(value: T): T | undefined {
    const ref = useRef<T>();
    useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref.current;
}

/**
 * Get the input accepted file types.
 */
export function getInputAcceptedTypes(array: string[]): string {
    let accept = '';
    array.forEach(type => {
        accept += '.' + type + ',';
    });
    accept = accept.slice(0, -1);
    return accept;
}

/**
 * The regex will extract the name and the extension from fileName.
 * @param fileName
 */
export function getFileExtension(fileName: string): string | undefined {
    return fileName.split(/\.(?=[^.]+$)/)[1];
}

/**
 * Used to change the background color to a custom color.
 */
function changeBackgroundColor(className: string): void {
    const rootHTMLElement = document.getElementById('root');

    if (rootHTMLElement) {
        rootHTMLElement.classList.add(className);
    }
}

/**
 * Used to remove the custom background.
 */
function removeBackgroundColor(className: string): void {
    const rootHTMLElement = document.getElementById('root');

    if (rootHTMLElement) {
        rootHTMLElement.classList.remove(className);
    }
}

/**
 * Used to switch the app bar mode to edit mode.
 */
export function useEditMode(onClose: () => void, onDone: () => Promise<void>): void {
    const appBarContext = useContext(AppBarContext);

    useEffect(() => {
        appBarContext.setContextValue({
            ...appBarContext.contextValue,
            mode: APP_BAR_MODE.EDIT,
            title: 'contacts:edit',
            onClose: onClose,
            onDone: onDone
        });
        // at the first render update the app bar to edit mode
        // and register the onDone and onClose callbacks
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
}

/**
 * Used to change the background color of a component.
 * @param backgroundColor
 */
export function useBackgroundColor(backgroundColor: string): void {
    useEffect(() => {
        changeBackgroundColor(backgroundColor);

        return () => removeBackgroundColor(backgroundColor);
    }, [backgroundColor]);
}

/**
 * Used to catch the errors when an image is uploaded.
 * @param uploadedFile
 */
export function getUploadImageError(uploadedFile: File): string {
    const uploadedFileExtension = getFileExtension(uploadedFile.name)?.toLowerCase();

    // if the file doesn't have an extension, display the error
    if (uploadedFileExtension === undefined) {
        return 'common:errors.fileUpload.corruptedFile';
    }

    // if the file extension doesn't exist in the accepted file format array then display the error
    if (FILE_FORMATS.find(element => element === uploadedFileExtension) === undefined) {
        return 'common:errors.fileUpload.pictureFormatNotAccepted';
    }

    return '';
}

/**
 * Custom hook for getting the size of the window.
 * @returns {number[]} - width and height of the window.
 */
export function useWindowSize(): number[] {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize(): void {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

/**
 * Used to catch the 'Enter' key press event and execute an action based on that key press.
 * @param event - the caught event.
 * @param action - a function which is executed when 'Enter' is pressed.
 */
export function onEnterPress(event: KeyboardEvent<HTMLDivElement>, action: () => void): void {
    if (event.key === 'Enter') {
        action();
    }
}

/**
 * @param pdfPath relative path to the pdf
 * @returns {Promise<File>}
 */
export async function getFileFromStaticPDFPath(pdfPath: string): Promise<File> {
    const response = await fetch(pdfPath);
    const blob = await response.blob();
    const name = (pdfPath.split('/').pop() || '').replace(/\.[0-9a-zA-Z]+\./, '.') || 'unnamed.pdf';
    return new File([blob], name, {type: 'application/pdf'});
}

export function showIdentityVerificationCode(): boolean {
    return localStorage.getItem('isIdentityVerified') === 'false';
}

export function displayIdentityVerificationCode(display: boolean): void {
    localStorage.setItem('isIdentityVerified', display ? 'false' : 'true');
}

export function isDateBetween(date: Date, from: Date, to: Date, inclusivity = '[]') {
    if (!['()', '[]', '(]', '[)'].includes(inclusivity)) {
        throw new Error('Inclusivity parameter must be one of (), [], (], [)');
    }

    const isBeforeEqual = inclusivity[0] === '[',
        isAfterEqual = inclusivity[1] === ']';

    return (
        (isBeforeEqual ? isEqual(from, date) || isBefore(from, date) : isBefore(from, date)) &&
        (isAfterEqual ? isEqual(to, date) || isAfter(to, date) : isAfter(to, date))
    );
}
