import type {Position} from '../../../types/svg';

export function polarToCartesian(
    position: Position,
    trackerRadius: number,
    angleInDegrees: number
): Position {
    var angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

    return {
        x: position.x + trackerRadius * Math.cos(angleInRadians),
        y: position.y + trackerRadius * Math.sin(angleInRadians)
    };
}

export function describeArc(
    position: Position,
    radius: number,
    startAngle: number,
    endAngle: number
): string {
    var start = polarToCartesian(position, radius, endAngle);
    var end = polarToCartesian(position, radius, startAngle);

    var largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

    var d = ['M', start.x, start.y, 'A', radius, radius, 0, largeArcFlag, 0, end.x, end.y].join(
        ' '
    );

    return d;
}

// https://www.codegrepper.com/code-examples/javascript/frameworks/express/calculate+text+length+in+pixels+javascript
export function getTextPxFromPxFont(str: string, pxFontSize: number): number {
    const widths = [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0.2796875, 0.2765625, 0.3546875, 0.5546875, 0.5546875, 0.8890625, 0.665625, 0.190625,
        0.3328125, 0.3328125, 0.3890625, 0.5828125, 0.2765625, 0.3328125, 0.2765625, 0.3015625,
        0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.5546875,
        0.5546875, 0.5546875, 0.2765625, 0.2765625, 0.584375, 0.5828125, 0.584375, 0.5546875,
        1.0140625, 0.665625, 0.665625, 0.721875, 0.721875, 0.665625, 0.609375, 0.7765625, 0.721875,
        0.2765625, 0.5, 0.665625, 0.5546875, 0.8328125, 0.721875, 0.7765625, 0.665625, 0.7765625,
        0.721875, 0.665625, 0.609375, 0.721875, 0.665625, 0.94375, 0.665625, 0.665625, 0.609375,
        0.2765625, 0.3546875, 0.2765625, 0.4765625, 0.5546875, 0.3328125, 0.5546875, 0.5546875, 0.5,
        0.5546875, 0.5546875, 0.2765625, 0.5546875, 0.5546875, 0.221875, 0.240625, 0.5, 0.221875,
        0.8328125, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.3328125, 0.5, 0.2765625, 0.5546875,
        0.5, 0.721875, 0.5, 0.5, 0.5, 0.3546875, 0.259375, 0.353125, 0.5890625
    ];
    const avg = 0.5279276315789471;
    return (
        str
            .split('')
            .map(c => (c.charCodeAt(0) < widths.length ? widths[c.charCodeAt(0)] : avg))
            .reduce((cur, acc) => acc + cur) * pxFontSize
    );
}

export function vwTOpx(value: number) {
    var w = window,
        d = document,
        e = d.documentElement,
        g = d.getElementsByTagName('body')[0],
        x = w.innerWidth || e.clientWidth || g.clientWidth;
    return (x * value) / 100;
}
