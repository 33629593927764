import type {ReactElement} from 'react';

import type {CycleBarInformation} from '../types';
import Path from './Path';

/**
 * render main bar
 * @param {number} props.endDay
 * @param {CycleBarInformation} props.cycleBarCalculations
 * @returns
 */
export function MainBar(props: {
    endDay: number;
    cycleBarInformation: CycleBarInformation;
}): ReactElement {
    const to = {
        x: props.endDay * props.cycleBarInformation.dayWidth,
        y: props.cycleBarInformation.end.y
    };
    return (
        <Path
            from={props.cycleBarInformation.start}
            to={to}
            color={props.cycleBarInformation.color}
            strokeWidth={props.cycleBarInformation.strokeWidth}
        />
    );
}
