import type {ReactElement} from 'react';
import {Icon} from './Icon';
import './AddToHomeScreen.css';
import Paper from '@mui/material/Paper';
import {getUA} from 'react-device-detect';
/* eslint import/no-webpack-loader-syntax: off */
// workaround which uses the fact that webpack is used under the hood
// source: https://github.com/facebook/create-react-app/issues/11770#issuecomment-1022024494
import homescreen_icon from '!file-loader!../resources/homescreen_icon.svg';
import ipadgrafik from '!file-loader!../resources/ipadgrafik.svg';
import threephones from '!file-loader!../resources/3phones.svg';
import {Button} from '@mui/material';
import {useTranslation} from 'react-i18next';

export default function AddToHomeScreen(): ReactElement {
    const regex = RegExp('iPad', 'i');
    const isiPad: boolean = regex.exec(getUA) !== null;
    const i18n = useTranslation();

    return (
        <div className="add-to-home-screen-container">
            <Paper square elevation={3} className="page-container">
                <div className="add-to-home-screen-header">
                    <Icon name="Info" />
                    <h2 className="headline margin-top">{i18n.t('addToHomeScreen:title')}</h2>
                </div>
                <div>
                    {i18n.t('addToHomeScreen:paragraph1')}
                    <span className="bold-text">{i18n.t('addToHomeScreen:paragraph1Bold')}</span>
                </div>
                <div className="italic-text">{i18n.t('addToHomeScreen:paragraph2')}</div>
                <div className="sth-steps-container">
                    <div className="left-step-wrapper">
                        <div className="bold-text">{i18n.t('addToHomeScreen:step1')}</div>
                        <div className="step1-description">
                            {i18n.t('addToHomeScreen:step1DescriptionPart1')}
                            <img src={homescreen_icon} alt="logo" className="homescreen-icon" />
                            <span>
                                {isiPad
                                    ? i18n.t('addToHomeScreen:step1DescriptionPart2-iPad')
                                    : i18n.t('addToHomeScreen:step1DescriptionPart2-iPhone')}
                            </span>
                        </div>
                    </div>
                    <div className="step-wrapper">
                        <div className="bold-text">{i18n.t('addToHomeScreen:step2')}</div>
                        <div>
                            {i18n.t('addToHomeScreen:step2DescriptionPart1')}
                            <span className="bold-text">
                                {i18n.t('addToHomeScreen:addToHomeScreen')}
                            </span>
                            <span>{i18n.t('addToHomeScreen:step2DescriptionPart2')}</span>
                            <span className="bold-text">{i18n.t('addToHomeScreen:add')}</span>
                            <span>{i18n.t('addToHomeScreen:step2DescriptionPart3')}</span>
                        </div>
                    </div>
                </div>
                <div className="step3-title bold-text">{i18n.t('addToHomeScreen:step3')}</div>
                <div>{i18n.t('addToHomeScreen:step3Description')}</div>
                <img
                    alt="logo"
                    className={`${isiPad ? 'sth-image-ipad' : ''}`}
                    src={isiPad ? ipadgrafik : threephones}
                />
                <div className="cnt-buttons-container">
                    <Button
                        color="primary"
                        variant="contained"
                        disabled={false}
                        className="ad2hs-continue"
                        aria-label={i18n.t('addToHomeScreen:continued')}
                        onClick={() => {
                            localStorage.setItem('skipAddToHomeScreen', 'true');
                            window.location.reload();
                        }}
                    >
                        {i18n.t('addToHomeScreen:continue')}
                    </Button>
                </div>
            </Paper>
        </div>
    );
}
