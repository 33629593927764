import type {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';

/* eslint import/no-webpack-loader-syntax: off */
// workaround which uses the fact that webpack is used under the hood
// source: https://github.com/facebook/create-react-app/issues/11770#issuecomment-1022024494
import CycleLength from '!file-loader!./../../resources/svgs/Cycle_Length.svg';
import CycleVariation from '!file-loader!./../../resources/svgs/Cycle_Variation.svg';
import PeriodLength from '!file-loader!./../../resources/svgs/Period_Length.svg';

import './Insights.css';
import CycleList from './CycleList';
import {useInsightsData} from '../hooks/cycle/cycles';

export default function Insights(): ReactElement {
    const i18n = useTranslation();
    // TODO: pass model to get data
    const {cycleVariation, periodLength, cycleLength} = useInsightsData();

    return (
        <div className="insights-container">
            <div className="insights-header">
                <div className="insights-header-container">
                    <div className="insights-header-item insights-cycle-length">
                        <img
                            src={CycleLength}
                            className="insights-header-item-svg"
                            alt={i18n.t('insights:cycleLength')}
                        />
                        <div className="insights-header-item-description">
                            {i18n.t('insights:cycleLength')}
                        </div>
                        <div className="insights-header-item-description insights-bold">
                            {cycleLength} {i18n.t('insights:days')}
                        </div>
                    </div>
                    <div className="insights-header-item insights-cycle-variation">
                        <img
                            src={CycleVariation}
                            className="insights-header-item-svg"
                            alt={i18n.t('insights:cycleVariation')}
                        />
                        <div className="insights-header-item-description">
                            {i18n.t('insights:cycleVariation')}
                        </div>
                        <div className="insights-header-item-description insights-bold">
                            + - {cycleVariation} {i18n.t('insights:days')}
                        </div>
                    </div>
                    <div className="insights-header-item insights-period-length">
                        <img
                            src={PeriodLength}
                            className="insights-header-item-svg"
                            alt={i18n.t('insights:periodLength')}
                        />
                        <div className="insights-header-item-description">
                            {i18n.t('insights:periodLength')}
                        </div>
                        <div className="insights-header-item-description insights-bold">
                            {periodLength} {i18n.t('insights:days')}
                        </div>
                    </div>
                </div>
            </div>
            <div className="insights-content">
                <CycleList />
            </div>
        </div>
    );
}
