import type {ReactElement} from 'react';
import {useEffect, useState} from 'react';
import Paper from '@mui/material/Paper';
import {ReactMarkdown} from 'react-markdown/lib/react-markdown';

/**
 * The markdown paper view component.
 * @param props
 * @param props.filePath - the filePath of the markdown file that will be displayed.
 * @returns
 */
export default function MarkdownPaperView(props: {filePath: string}): ReactElement {
    const [markdownContent, setMarkdownContent] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        if (props.filePath) {
            const markdownFullFilePath = require('../../../' + props.filePath);

            fetch(markdownFullFilePath)
                .then(res => res.text())
                .then(content => {
                    setMarkdownContent(content);
                })
                .catch(error => {
                    setErrorMessage(error);
                });
        }
    }, [props.filePath]);

    return (
        <Paper square elevation={3} className="page-content-box paper-font-size">
            {errorMessage ? (
                errorMessage
            ) : (
                <ReactMarkdown linkTarget="_blank">{markdownContent}</ReactMarkdown>
            )}
        </Paper>
    );
}
