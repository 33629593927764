import {useState} from 'react';
import type {AppBarData} from './context/AppBarContext';
import {AppBarContext} from './context/AppBarContext';
import type Model from '../models/Model';
import {Router} from '../Router';
import type {NotificationType} from './components/SnackbarNotification';
import SnackbarNotification, {
    NOTIFICATION,
    NotificationContext
} from './components/SnackbarNotification';
import {APP_BAR_MODE} from './appBar/AppBar';
import {MarkdownContext} from './context/MarkdownPopupContext';
import MarkdownPopup from './components/markdownPopup/MarkdownPopup';
import {useVHPhoneFixById} from './hooks/device/window';

/**
 * Builds the UI component which is the main component of the application
 */
export default function Ui(props: {model: Model}) {
    const [contextValue, setContextValue] = useState<AppBarData>({
        title: '',
        isMenuBtnClicked: false,
        isMoreBtnClicked: false,
        statusFilters: [],
        mode: APP_BAR_MODE.BROWSE,
        onDone: () => {
            return;
        },
        onClose: () => {
            return;
        }
    });
    const [notificationMessage, setNotificationMessage] = useState<string>('');
    const [notificationType, setNotificationType] = useState<NotificationType>(
        NOTIFICATION.Success
    );

    // used to handle the markdown files
    const [markdownFilePath, setMarkdownFilePath] = useState<string>('');

    useVHPhoneFixById('root');

    return (
        <AppBarContext.Provider value={{contextValue, setContextValue}}>
            <NotificationContext.Provider
                value={{
                    setNotificationMessage: setNotificationMessage,
                    setNotificationType: setNotificationType
                }}
            >
                <MarkdownContext.Provider value={{setMarkdownFilePath: setMarkdownFilePath}}>
                    <Router model={props.model} />

                    <SnackbarNotification
                        message={notificationMessage}
                        setMessageCallback={setNotificationMessage}
                        type={notificationType}
                    />

                    {markdownFilePath !== '' && (
                        <MarkdownPopup
                            filePath={markdownFilePath}
                            setFilePath={setMarkdownFilePath}
                        />
                    )}
                </MarkdownContext.Provider>
            </NotificationContext.Provider>
        </AppBarContext.Provider>
    );
}
