import type {ReactElement} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import './CustomDialog.css';
import {IconButton} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export default function CustomDialog(props: {
    onClose: () => void;
    title?: string;
    content?: ReactElement;
    action?: ReactElement;
    displayCloseButton?: boolean;
    isCentered?: boolean;
    className?: string;
}): ReactElement {
    return (
        <Dialog
            className={`custom-modal ${props.isCentered ? 'center' : ''} ${props.className}`}
            open={true}
            onClose={props.onClose}
        >
            {props.displayCloseButton && (
                <div className="custom-dialog-close">
                    <IconButton className="custom-dialog-close-button" onClick={props.onClose}>
                        <CloseIcon />
                    </IconButton>
                </div>
            )}
            {props.title && <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>}
            {props.content && <DialogContent>{props.content}</DialogContent>}
            {props.action && <DialogActions>{props.action}</DialogActions>}
        </Dialog>
    );
}
