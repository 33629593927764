import type {ReactElement} from 'react';

import type {SectionParams} from '../../../types/svg';
import type {CycleBarInformation} from '../types';
import Path from './Path';

/**
 * Draw segmented sections
 * @param {SectionParams[]} [props.sections]
 * @param {CycleBarInformation} [props.cycleBarInformation]
 * @returns
 */
export default function SegmentedSections(props: {
    sections?: SectionParams[];
    cycleBarInformation: CycleBarInformation;
}): ReactElement {
    return (
        <>
            {props.sections?.map((section, index) => {
                const lineCapRadius = props.cycleBarInformation.strokeWidth / 2;

                // calculate and adjust line path
                const to = {
                    x: (section.end - section.start) * props.cycleBarInformation.dayWidth,
                    y: props.cycleBarInformation.end.y
                };
                const from = {
                    x: lineCapRadius,
                    y: props.cycleBarInformation.end.y
                };
                if (to.x < from.x) {
                    to.x = from.x;
                }

                // calculate and adjust relative box
                const sectionStart = {
                    x: (section.start - 1) * props.cycleBarInformation.dayWidth - lineCapRadius,
                    y: 0
                };
                if (sectionStart.x < props.cycleBarInformation.start.x) {
                    sectionStart.x = props.cycleBarInformation.start.x - lineCapRadius;
                }

                return (
                    <g key={index} transform={`translate(${sectionStart.x},${sectionStart.y})`}>
                        <svg
                            width={
                                (section.end - section.start) * props.cycleBarInformation.dayWidth +
                                lineCapRadius
                            }
                            height={props.cycleBarInformation.viewBox.y}
                        >
                            <Path
                                from={from}
                                to={to}
                                color={section.color ? section.color : ''}
                                strokeWidth={props.cycleBarInformation.strokeWidth}
                                id={`section-${index}`}
                            />
                            {section.text && (
                                <text
                                    x="50%"
                                    y={props.cycleBarInformation.start.y}
                                    className="cycle-bar-marker-day"
                                    textAnchor="middle"
                                    alignmentBaseline="central"
                                    dominantBaseline="central"
                                    fill={section.textColor}
                                >
                                    {section.text}
                                </text>
                            )}
                        </svg>
                    </g>
                );
            })}
        </>
    );
}
