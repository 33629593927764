import type {SectionParams} from '../../types/svg';

export type CycleItem = {
    dateStarted: Date;
    length: number;
    currentDay?: number;
    segmentSections: SectionParams[] | undefined;
};

export type CycleListData = {
    cycles: CycleItem[];
};

export function useCycleListData(): CycleListData {
    // TODO: get values from models
    // Start of mockup data
    const currentCycle = new Date();
    currentCycle.setDate(new Date().getDate() - 19);
    const lastCycle = new Date();
    lastCycle.setDate(new Date().getDate() - (19 + 31));
    return {
        cycles: [
            {
                dateStarted: currentCycle,
                length: 28,
                currentDay: 19,
                segmentSections: [
                    {
                        start: 1,
                        end: 3,
                        color: '#EA3C53',
                        text: '3',
                        textColor: 'white'
                    },
                    {
                        start: 13,
                        end: 23,
                        color: '#00FEC2'
                    }
                ]
            },
            {
                dateStarted: lastCycle,
                length: 31,
                segmentSections: [
                    {
                        start: 1,
                        end: 2,
                        color: '#EA3C53',
                        text: '2',
                        textColor: 'white'
                    },
                    {
                        start: 16,
                        end: 28,
                        color: '#00FEC2'
                    }
                ]
            },
            {
                dateStarted: lastCycle,
                length: 48,
                segmentSections: [
                    {
                        start: 1,
                        end: 5,
                        color: '#EA3C53',
                        text: '5',
                        textColor: 'white'
                    },
                    {
                        start: 25,
                        end: 38,
                        color: '#00FEC2'
                    }
                ]
            }
            // etc
        ]
    };
}

export function useInsightsData() {
    // TODO: get values from models
    // mockup data
    return {
        cycleVariation: 4,
        periodLength: 4,
        cycleLength: 4
    };
}
