import type {Cycle, Expanded, MeasurementBleeding} from './models/recipes';

// NOTE: This example data only represents one state of the objects.
//       All dataobjects are versioned and the `entered_at` date will depend on the version you are looking at.
const bleedingMeasurements: Expanded<MeasurementBleeding>[] = [
    {
        $type$: 'MeasurementBleeding',
        day: '2022-01-01',
        value: 'none',
        entered_at: {
            $type$: 'DateTime',
            utcTimestamp: new Date('2022-01-01T06:12:00.000Z').getTime(),
            timezone: 'Europe/Berlin'
        }
    },
    {
        $type$: 'MeasurementBleeding',
        day: '2022-01-02',
        value: 'none',
        entered_at: {
            $type$: 'DateTime',
            utcTimestamp: new Date('2022-01-02T14:02:00.000Z').getTime(),
            timezone: 'Europe/Berlin'
        }
    },
    {
        $type$: 'MeasurementBleeding',
        day: '2022-01-03',
        value: 'none',
        entered_at: {
            $type$: 'DateTime',
            utcTimestamp: new Date('2022-01-03T08:45:00.000Z').getTime(),
            timezone: 'Europe/Berlin'
        }
    },
    {
        $type$: 'MeasurementBleeding',
        day: '2022-01-04',
        value: 'none',
        entered_at: {
            $type$: 'DateTime',
            utcTimestamp: new Date('2022-01-05T13:40:00.000Z').getTime(),
            timezone: 'Europe/Berlin'
        }
    }
];

const cycles: Cycle[] = [
    {$type$: 'Cycle', id: 1, startDay: '2022-01-01', excluded: false},
    {$type$: 'Cycle', id: 2, startDay: '2022-01-30', excluded: false}
];


export {bleedingMeasurements, cycles};