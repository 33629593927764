import type {SingleUser} from 'one.models/lib/models/Authenticator';

import {logout} from '../../models/modelHelper/OneInstanceHelper';
import type {
    NavigationDrawerMenuItem,
    NavigationDrawerBottomItem
} from 'one.ui/lib/ui/components/menu/NavigationDrawer';
import i18n from '../../i18n';

/**
 * Returns the list of the menu items
 */
export function getMenuItems(
    setMarkDown: (translationPath: string) => void
): NavigationDrawerMenuItem[] {
    return [
        {
            name: i18n.t('common:menu.dashboard'),
            path: '/'
        },
        {
            name: i18n.t('common:menu.insights'),
            path: '/insights'
        },
        {
            name: i18n.t('common:menu.settings'),
            path: '/settings'
        },
        {
            name: i18n.t('blut:blutInfos'),
            subItems: getInfoItems(setMarkDown)
        }
    ];
}

function getInfoItems(setMarkDown: (translationPath: string) => void): NavigationDrawerMenuItem[] {
    return [
        {
            name: i18n.t('blut:impressum'),
            onClick: () => setMarkDown('blut:mdFiles.impressum')
        },
        {
            name: i18n.t('blut:privacyPolicy'),
            onClick: () => setMarkDown('blut:mdFiles.privacyPolicyPWA')
        },
        {
            name: i18n.t('blut:terms'),
            onClick: () => setMarkDown('blut:mdFiles.termsOfUse')
        },
        {
            name: i18n.t('blut:license'),
            onClick: () => setMarkDown('blut:mdFiles.license')
        }
    ];
}

export function getBottomItems(one: SingleUser): NavigationDrawerBottomItem[] {
    return [
        {
            name: i18n.t('common:logout'),
            onClick: () => logout(one)
        }
    ];
}
