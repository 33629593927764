import {useEffect, useState} from 'react';
import * as React from 'react';
import {useAuthenticationState, useIsRegistered} from './models/modelHelper/OneInstanceHelper';
import {BrowserRouter, Outlet, Route, Routes} from 'react-router-dom';
import InstancesSettingsView from './ui/settings/InstancesSettingsView';
import RegisterDebugRoutes from './ui/RegisterDebugRoutes';
import type Model from './models/Model';
import Menu from './ui/menu/Menu';
import AppBar from './ui/appBar/AppBar';
import DetailedInstanceSettingsView from './ui/settings/DetailedInstanceSettingsView';
import './ui/Ui.css';
import './Primary.css';
import Login from './ui/Login';
import Registration from './ui/registration/Registration';
import {CircularProgress} from '@mui/material';
import {isIOS, isMobile, isSafari} from 'react-device-detect';
import {isStandalone, showIdentityVerificationCode} from './ui/Utils';
import AddToHomeScreen from './ui/AddToHomeScreen';
import AppBarSettings from './ui/appBar/AppBarSettings';
import {useVHPhoneFixRef} from './ui/hooks/device/window';
import {useTranslation} from 'react-i18next';
import Cycle from './ui/cycle/Cycle';
import Track from './ui/track/Track';
import Insights from './ui/insights/Insights';

/**
 * The Router
 * @param props
 * @constructor
 */
export function Router(props: {model: Model}): React.ReactElement {
    const {one, leuteModel, instancesModel, channelManager} = props.model;
    const authenticationState = useAuthenticationState(one);
    const isRegistered = useIsRegistered(one);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const i18n = useTranslation();

    useEffect(() => {
        setIsLoggedIn(authenticationState === 'logged_in' && !showIdentityVerificationCode());
    }, [authenticationState]);

    /**
     * Renders the root component based on the auth & on boarding state
     */
    function renderLoginComponent(): React.ReactElement | null {
        if (authenticationState === 'logging_in' || authenticationState === 'logging_out') {
            return <CircularProgress className="circular-progress" size={35} />;
        }

        if (
            isIOS &&
            isMobile &&
            isSafari &&
            !isStandalone() &&
            localStorage.getItem('skipAddToHomeScreen') !== 'true'
        ) {
            return <AddToHomeScreen />;
        }

        return isRegistered ? <Login one={one} /> : <Registration one={one} />;
    }

    const container = useVHPhoneFixRef<HTMLDivElement>();

    return (
        <BrowserRouter>
            <div className={`${isLoggedIn ? `app-container` : 'app-wrapper'}`} ref={container}>
                <Routes>
                    {!isLoggedIn ? (
                        <Route path="*" element={renderLoginComponent()} />
                    ) : (
                        <Route>
                            <Route
                                path="/"
                                element={
                                    <>
                                        <AppBar />
                                        <Menu leuteModel={leuteModel} one={one} />
                                        <Outlet />
                                    </>
                                }
                            >
                                <Route
                                    index
                                    element={
                                        <>
                                            <AppBarSettings
                                                title={i18n.t('common:menu.dashboard')}
                                                hide={false}
                                            />
                                            <Cycle model={props.model} />
                                        </>
                                    }
                                />
                                <Route path="track/*">
                                    <Route
                                        index
                                        element={
                                            <>
                                                <AppBarSettings hide={true} />
                                                <Track model={props.model} />
                                            </>
                                        }
                                    />
                                    <Route
                                        path=":day/:month/:year"
                                        element={
                                            <>
                                                <AppBarSettings hide={true} />
                                                <Track model={props.model} />
                                            </>
                                        }
                                    />
                                </Route>
                                <Route
                                    path="insights/*"
                                    element={
                                        <>
                                            <AppBarSettings
                                                title={i18n.t('common:menu.insights')}
                                                hide={false}
                                            />
                                            <Insights />
                                        </>
                                    }
                                />
                                <Route path="settings">
                                    <Route
                                        index
                                        element={
                                            <>
                                                <AppBarSettings
                                                    title={i18n.t('common:menu.settings')}
                                                    hide={false}
                                                />
                                                <InstancesSettingsView
                                                    instancesModel={instancesModel}
                                                    one={one}
                                                />
                                            </>
                                        }
                                    />
                                    <Route
                                        path=":personId"
                                        element={
                                            <>
                                                <AppBarSettings
                                                    title={i18n.t('common:menu.settings')}
                                                    hide={false}
                                                />
                                                <DetailedInstanceSettingsView
                                                    instancesModel={instancesModel}
                                                    leuteModel={leuteModel}
                                                    one={one}
                                                />
                                            </>
                                        }
                                    />
                                </Route>
                                <Route
                                    path="debug/*"
                                    element={
                                        <RegisterDebugRoutes
                                            leuteModel={leuteModel}
                                            instancesModel={instancesModel}
                                            channelManager={channelManager}
                                        />
                                    }
                                />
                            </Route>
                        </Route>
                    )}
                </Routes>
            </div>
        </BrowserRouter>
    );
}
