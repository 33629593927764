import type {ReactElement} from 'react';
import React from 'react';
import {useTranslation} from 'react-i18next';

export default function MarkerWithDayNumber(props: {
    text: string;
    posX: number;
    posY: number;
    circleProps: React.SVGProps<SVGCircleElement>;
    textProps: React.SVGProps<SVGTextElement>;
    borderProps?: React.SVGProps<SVGCircleElement>;
}): ReactElement {
    const i18n = useTranslation();
    const {text, posX, posY, circleProps, textProps, borderProps} = props;
    return (
        <g>
            {borderProps && <circle cx={posX} cy={posY} fill="transparent" {...borderProps} />}
            <circle cx={posX} cy={posY} {...circleProps} />
            <text
                x={posX}
                y={posY - 15}
                className="cycle-tracker-marker cycle-tracker-marker-day"
                textAnchor="middle"
                alignmentBaseline="central"
                dominantBaseline="central"
                {...textProps}
            >
                {i18n.t('common:day').toUpperCase()}
            </text>
            <text
                x={posX}
                y={posY + 15}
                className="cycle-tracker-marker cycle-tracker-marker-text"
                textAnchor="middle"
                alignmentBaseline="central"
                dominantBaseline="central"
                {...textProps}
            >
                {text}
            </text>
        </g>
    );
}
