import type {ReactElement} from 'react';

import type {Position} from '../../../types/svg';

/**
 * render line path
 * @param {Position} props.from
 * @param {Position} props.to
 * @param {string} props.color
 * @returns
 */
export default function Path(props: {
    from: Position;
    to: Position;
    color?: string;
    strokeWidth?: number;
    id?: string;
}): ReactElement {
    return (
        <path
            d={`M ${props.from.x} ${props.from.y} L ${props.to.x} ${props.to.y}`}
            stroke={props.color}
            strokeWidth={props.strokeWidth ? `${props.strokeWidth}px` : undefined}
            strokeLinecap="round"
            id={props.id}
        />
    );
}
