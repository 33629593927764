import type {ReactElement} from 'react';
import {useContext, useEffect} from 'react';
import {AppBarContext} from '../context/AppBarContext';
import type {AppBarMode} from './AppBar';

export default function AppBarSettings(props: {
    title?: string;
    mode?: AppBarMode;
    hide?: boolean;
}): ReactElement {
    const {contextValue, setContextValue} = useContext(AppBarContext);
    useEffect(() => {
        if (props.title && contextValue.title !== props.title) {
            setContextValue({
                ...contextValue,
                title: props.title
            });
        }
        if (props.mode && contextValue.mode !== props.mode) {
            setContextValue({
                ...contextValue,
                mode: props.mode
            });
        }
        if (props.hide !== undefined && contextValue.hide !== props.hide) {
            setContextValue({
                ...contextValue,
                hide: props.hide
            });
        }
    }, [contextValue, setContextValue, props]);
    return <></>;
}
