import type {ReactElement} from 'react';
import {useContext} from 'react';
import {useTranslation} from 'react-i18next';

import type {LeuteModel} from 'one.models/lib/models';
import type {SingleUser} from 'one.models/lib/models/Authenticator';
import NavigationDrawer from 'one.ui/lib/ui/components/menu/NavigationDrawer';

import {getBottomItems, getMenuItems} from './MenuItems';
import {useMarkdownContext} from '../context/MarkdownPopupContext';
import {AppBarContext} from '../context/AppBarContext';

/**
 * Builds the left menu
 */
export default function Menu(props: {leuteModel: LeuteModel; one: SingleUser}): ReactElement {
    const {setMarkdownFilePath} = useMarkdownContext();
    const i18n = useTranslation();
    const {contextValue, setContextValue} = useContext(AppBarContext);

    function setmarkDown(translatePath: string) {
        setMarkdownFilePath(i18n.t(translatePath));
    }

    return (
        <NavigationDrawer
            items={getMenuItems(setmarkDown)}
            bottomItems={getBottomItems(props.one)}
            withDebugItems={true}
            open={contextValue.isMenuBtnClicked}
            onClose={() => {
                setContextValue({
                    ...contextValue,
                    isMenuBtnClicked: false,
                    isMoreBtnClicked: false
                });
            }}
        />
    );
}
