import type {ReactElement} from 'react';
import {useRef, useState} from 'react';
import {Button, Checkbox, FormControlLabel, TextField} from '@mui/material';
import {useTranslation} from 'react-i18next';

/* eslint import/no-webpack-loader-syntax: off */
// workaround which uses the fact that webpack is used under the hood
// source: https://github.com/facebook/create-react-app/issues/11770#issuecomment-1022024494
import PasswordSVG from '!file-loader!./../../resources/svgs/password.svg';

import {NOTIFICATION, useNotificationContext} from '../components/SnackbarNotification';
import './Registration.css';
import {useMarkdownContext} from '../context/MarkdownPopupContext';
import {onEnterPress} from '../Utils';

export default function Password(props: {
    onValidPassword: (password: string) => void;
}): ReactElement {
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [agreeTerms, setAgreeTerms] = useState(false);
    const [confirmAge, setConfirmAge] = useState(false);
    const {setNotificationMessage, setNotificationType} = useNotificationContext();
    const {setMarkdownFilePath} = useMarkdownContext();
    const confirmPasswordInputRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const passwordInputRef = useRef<HTMLDivElement>(null);
    const i18n = useTranslation();

    const isButtonDisabled =
        !agreeTerms || !confirmAge || password === '' || passwordConfirmation === '';

    async function checkPasswordMatch(): Promise<void> {
        if (password !== passwordConfirmation) {
            setNotificationMessage('common:errors.passwordDoesNotMatch');
            setNotificationType(NOTIFICATION.Error);
        } else {
            props.onValidPassword(password);
        }
    }

    return (
        <div className="blut-registration">
            <div className="blut-page-content">
                <div className="blut-register-image">
                    <img src={PasswordSVG} alt={i18n.t('blut:password')} />
                </div>
                <div className="blut-information title">{i18n.t('blut:thank')}</div>
                <div className="blut-information">{i18n.t('blut:setPassword')}</div>
                <form className="password-form">
                    <TextField
                        inputRef={passwordInputRef}
                        className="text-field-entry"
                        value={password}
                        label={i18n.t('blut:password')}
                        variant="standard"
                        type="password"
                        fullWidth={true}
                        onChange={event => setPassword(event.target.value)}
                        InputProps={{autoComplete: 'on'}}
                        onKeyPress={e => {
                            onEnterPress(e, () => {
                                if (confirmPasswordInputRef && confirmPasswordInputRef.current) {
                                    confirmPasswordInputRef.current.focus();
                                }
                            });
                        }}
                    />
                    <TextField
                        inputRef={confirmPasswordInputRef}
                        className="text-field-entry"
                        value={passwordConfirmation}
                        label={i18n.t('blut:confirmPassword')}
                        variant="standard"
                        fullWidth={true}
                        type="password"
                        onChange={event => setPasswordConfirmation(event.target.value)}
                        InputProps={{autoComplete: 'on'}}
                        onKeyPress={e => {
                            onEnterPress(e, () => {
                                if (buttonRef && buttonRef.current) {
                                    buttonRef.current.click();
                                }
                            });
                        }}
                    />
                </form>
                <FormControlLabel
                    control={<Checkbox onChange={() => setAgreeTerms(!agreeTerms)} />}
                    label={
                        <div className="accept-checkbox password" onClick={e => e.preventDefault()}>
                            {i18n.t('blut:acceptTerms')}
                            <div
                                className="blut-link"
                                onClick={() =>
                                    setMarkdownFilePath(i18n.t('blut:mdFiles.termsOfUse'))
                                }
                            >
                                {i18n.t('blut:terms')}
                            </div>
                            {i18n.t('blut:and')}
                            <div
                                className="blut-link"
                                onClick={() =>
                                    setMarkdownFilePath(i18n.t('blut:mdFiles.privacyPolicyPWA'))
                                }
                            >
                                {i18n.t('blut:privacy')}
                            </div>
                            <div className="point">.</div>
                        </div>
                    }
                />
                <FormControlLabel
                    control={<Checkbox onChange={() => setConfirmAge(!confirmAge)} />}
                    label={
                        <div className="accept-checkbox password" onClick={e => e.preventDefault()}>
                            {i18n.t('blut:confirmAge')}
                        </div>
                    }
                />
            </div>
            <Button
                ref={buttonRef}
                color="primary"
                variant="text"
                className={`big-rounded blut-btn blue-bg registerPassword ${
                    isButtonDisabled ? 'disabled' : ''
                }`}
                disabled={isButtonDisabled}
                onClick={checkPasswordMatch}
            >
                {i18n.t('blut:next')}
            </Button>
        </div>
    );
}
