import type {ReactElement} from 'react';
import {useEffect} from 'react';
import {useState} from 'react';
import type {CycleText, TrackerInformation} from '../types';
import {getTextPxFromPxFont, vwTOpx} from './utils';

export type GetInfoCallBack = (currentDay: number) => CycleText[];

/**
 * Draw informational text if callback is provided
 * @param {number} [props.currentDay]
 * @param {TrackerInformation} [props.trackerInformation]
 * @param {GetInfoCallBack} [props.middleText]
 * @returns
 */
export default function InformationalMiddleText(props: {
    currentDay: number;
    trackerInformation: TrackerInformation;
    getTextsCallback?: GetInfoCallBack;
}): ReactElement | null {
    const [svgText, setSvgText] = useState<ReactElement[]>([]);

    // calculate text font size and dynamic y position
    useEffect(() => {
        if (props.getTextsCallback === undefined || props.getTextsCallback.length <= 0) {
            return;
        }
        const middleText = props.getTextsCallback ? props.getTextsCallback(props.currentDay) : [];

        setSvgText(
            middleText.map((str, index) => {
                // dynamic font size calculations
                // also serves as y cordinate calculation
                let fontInPx = vwTOpx(30);
                const innerCirclePadding = vwTOpx(1);
                while (
                    getTextPxFromPxFont(str.text, fontInPx) >
                    props.trackerInformation.radius * 2 -
                        props.trackerInformation.circleStrokeWidth * 2 -
                        innerCirclePadding
                ) {
                    fontInPx = fontInPx - 1;
                }
                return (
                    <tspan
                        x="0"
                        dy={index * fontInPx}
                        fontWeight={str.fontWeight ? str.fontWeight : undefined}
                        key={index}
                        fontSize={fontInPx + 'px'}
                    >
                        {str.text}
                    </tspan>
                );
            })
        );
    }, [props]);

    if (svgText.length <= 0) {
        return null;
    }

    // dynamic centering logic for the text
    let y = 0;
    const fontInPx = props.trackerInformation.middleTextMaxPxFontSize;
    if (svgText.length > 1) {
        if (svgText.length % 2 === 1) {
            const middle = Math.floor(svgText.length / 2) + 1;
            y = middle * -fontInPx;
        } else {
            const middleLeft = Math.floor(svgText.length / 2);
            y = -(fontInPx / 2);
            if (middleLeft > 1) {
                y -= middleLeft * fontInPx;
            }
        }
    }

    return (
        <text x="0" y={y} textAnchor="middle" fill="black" fontFamily="Arial" dy="0.3em">
            {svgText}
        </text>
    );
}
