import {useNavigate} from 'react-router-dom';

/**
 * addition to react-router-dom v6 useNavigate, with default route
 * @param defaultRoute
 * @returns
 */
export function useNavigateBackFunction(defaultRoute: string = '/') {
    const navigate = useNavigate();
    return () => {
        const hasPreviousPage = window.history.state && window.history.state.idx > 0;
        if (hasPreviousPage) {
            navigate(-1);
        } else {
            navigate(defaultRoute, {replace: true});
        }
    };
}
