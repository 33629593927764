import type {ReactElement, ReactNode} from 'react';
import {useEffect, useContext} from 'react';

import {AppBarContext} from '../../context/AppBarContext';
import {APP_BAR_MODE} from '../../appBar/AppBar';
import {useVHPhoneFixRef} from '../../hooks/device/window';
import './FullScreenPopup.css';

export default function FullScreenPopup(props: {
    onClose?: () => void;
    title?: string;
    children?: ReactNode;
    className?: string;
}): ReactElement {
    const appBar = document.getElementById('app-bar-container');
    const appBarheight = appBar ? appBar.clientHeight : 0;
    const popupContent = useVHPhoneFixRef<HTMLDivElement>(-appBarheight);

    // store / restore AppBarContext
    const {contextValue, setContextValue} = useContext(AppBarContext);
    useEffect(() => {
        const oldContextValue = contextValue;
        setContextValue({
            ...contextValue,
            mode: APP_BAR_MODE.CHEVRON,
            title: props.title ? props.title : '',
            onClose: props.onClose ? props.onClose : () => {},
            hide: false
        });
        return () => {
            setContextValue(oldContextValue);
        };
    }, []);

    return (
        <div className={`fullscreen-popup-container ${props.className}`}>
            <div className="fullscreen-popup-content" ref={popupContent}>
                {props.children}
            </div>
        </div>
    );
}
