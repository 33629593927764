import type {ReactElement} from 'react';
import {Card, CardActions, CardContent} from '@mui/material';
import './CustomCard.css';

export default function CustomCard(props: {
    content: ReactElement;
    action?: ReactElement;
    className?: string;
}): ReactElement {
    return (
        <Card className={`custom-card ${props.className}`}>
            <CardContent>{props.content}</CardContent>
            {props.action !== undefined && <CardActions>{props.action}</CardActions>}
        </Card>
    );
}
