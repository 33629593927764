import type {ReactElement} from 'react';
import * as React from 'react';
import {useState} from 'react';
import {CircularProgress} from '@mui/material';
import WelcomePage from './WelcomePage';
import Password from './Password';
import type {SingleUser} from 'one.models/lib/models/Authenticator';

/**
 * Represents the registration steps.
 */
export const REGISTRATION_PROGRESS = {
    WELCOME_PAGE: 0,
    PASSWORD: 1
} as const;

/**
 * The type representing the registration process values.
 */
export type RegistrationProgress = typeof REGISTRATION_PROGRESS[keyof typeof REGISTRATION_PROGRESS];

export default function Registration(props: {one: SingleUser}): ReactElement {
    // state which tell us which page should be displayed
    const [registrationPage, setRegistrationPage] = useState<RegistrationProgress>(
        REGISTRATION_PROGRESS.WELCOME_PAGE
    );

    function gotoNextPage(): void {
        switch (registrationPage) {
            case REGISTRATION_PROGRESS.WELCOME_PAGE:
                setRegistrationPage(REGISTRATION_PROGRESS.PASSWORD);
                break;
        }
    }

    async function finishRegistration(password: string) {
        await props.one.register(password);
    }

    /**
     * The function that displays the registration step based on the "registration page" state.
     */
    function displayRegistrationPage(): React.ReactElement {
        switch (registrationPage) {
            case REGISTRATION_PROGRESS.WELCOME_PAGE:
                return <WelcomePage onDone={gotoNextPage} />;
            case REGISTRATION_PROGRESS.PASSWORD:
                return <Password onValidPassword={finishRegistration} />;
            default:
                return <CircularProgress className="circular-progress" size={35} />;
        }
    }

    return <>{displayRegistrationPage()}</>;
}
