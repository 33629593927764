import type {ReactElement} from 'react';
import React from 'react';

export default function MarkerWithDayNumber(props: {
    text: string;
    posX: number;
    posY: number;
    circleProps: React.SVGProps<SVGCircleElement>;
    textProps: React.SVGProps<SVGTextElement>;
    borderProps?: React.SVGProps<SVGCircleElement>;
}): ReactElement {
    return (
        <g>
            {props.borderProps && (
                <circle cx={props.posX} cy={props.posY} fill="transparent" {...props.borderProps} />
            )}
            <circle cx={props.posX} cy={props.posY} {...props.circleProps} />
            <text
                x={props.posX}
                y={props.posY}
                className="cycle-tracker-marker cycle-tracker-marker-text"
                textAnchor="middle"
                alignmentBaseline="central"
                dominantBaseline="central"
                {...props.textProps}
            >
                {props.text}
            </text>
        </g>
    );
}
