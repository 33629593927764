import type {ReactElement} from 'react';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import './InstancesSettingsView.css';
import './DetailedInstanceSettingsView.css';
import type {InstancesModel, LeuteModel} from 'one.models/lib/models';
import type {SHA256IdHash} from '@refinio/one.core/lib/util/type-checks';
import type {Instance, Person} from '@refinio/one.core/lib/recipes';
import {useNavigate, useParams} from 'react-router-dom';
import {Button, Typography} from '@mui/material';
import DeleteInstanceModal from './DeleteInstanceModal';
import {getObjectByIdHash} from '@refinio/one.core/lib/storage-versioned-objects';
import type {SingleUser} from 'one.models/lib/models/Authenticator';
import {NOTIFICATION, useNotificationContext} from '../components/SnackbarNotification';
import type ProfileModel from 'one.models/lib/models/Leute/ProfileModel';
import type {OneInstanceEndpoint} from 'one.models/lib/recipes/Leute/CommunicationEndpoints';
import CustomCard from '../components/CustomCard';

type InstanceSettingsDetails = {
    ownerName: string;
    deviceName: string;
    instance: InstanceDetails;
};

type InstanceDetails = {
    id: SHA256IdHash<Instance>;
    name: string;
    isLocal: string;
    owner: SHA256IdHash<Person>;
};

/**
 *  Detailed View for Settings
 */
export default function DetailedInstanceSettingsView(props: {
    instancesModel: InstancesModel;
    leuteModel: LeuteModel;
    one: SingleUser;
}): ReactElement {
    const {i18n} = useTranslation();

    const params = useParams<{personId: SHA256IdHash<Person> | undefined}>();
    const {setNotificationMessage, setNotificationType} = useNotificationContext();

    const [instanceSettingsDetails, setInstanceSettingsDetails] =
        useState<InstanceSettingsDetails>();
    const [deleteInstanceModal, setDeleteInstanceModal] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        async function fetchInstanceSettingsDetails(): Promise<void> {
            const {personId} = params;
            if (!personId) {
                return;
            }
            const mainProfile = await props.leuteModel.getMainProfile(personId);
            const personName = mainProfile.descriptionsOfType('PersonName');
            const ownerName = personName[0] ? personName[0].name : i18n.t('contacts:unknown');
            const instanceDetails = await getInstanceDetailsForProfile(mainProfile);

            setInstanceSettingsDetails({
                ownerName: ownerName,
                deviceName: `${ownerName}'s Device`,
                instance: instanceDetails
            });
        }

        fetchInstanceSettingsDetails().catch(() => {
            setNotificationType(NOTIFICATION.Error);
            setNotificationMessage('common:errors.settings');
            navigate('/settings', {replace: true});
        });
        // If we add getInstanceDetailsForProfile it would lead to a infinite render loop
        // todo refactor DetailedInstanceSettingsView
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function onDeleteInstanceButtonPress() {
        setDeleteInstanceModal(true);
    }

    function closeDeleteInstanceModal() {
        setDeleteInstanceModal(false);
    }

    async function handleDeleteInstance(): Promise<void> {
        navigate('/', {replace: true});
        try {
            await props.one.logoutAndErase();
        } catch (e) {
            if (
                e
                    .toString()
                    .includes(
                        "InvalidStateError: Failed to read the 'result' property from 'IDBRequest': The request has not finished."
                    )
            ) {
                props.one.authState.triggerEvent('logout_done');
            } else throw e;
        } finally {
            setNotificationType(NOTIFICATION.Success);
            setNotificationMessage('settings:deleteModal.onDeleteNotification');
        }
    }

    /**
     * Retrieves the instance details for the given profile
     * @param mainProfile
     */
    async function getInstanceDetailsForProfile(
        mainProfile: ProfileModel
    ): Promise<InstanceDetails> {
        try {
            const instanceId = (
                await props.instancesModel.localInstanceInfoForPerson(mainProfile.personId)
            ).instanceId;
            const {name} = (await getObjectByIdHash(instanceId)).obj;
            const isLocal = await props.instancesModel.isLocalInstance(instanceId);
            return {
                id: instanceId,
                name: name,
                isLocal: isLocal.toString(),
                owner: mainProfile.personId
            };
        } catch (e) {
            const endpoint = mainProfile.communicationEndpoints.find(
                endpoint => endpoint.$type$ === 'OneInstanceEndpoint'
            );
            if (endpoint === undefined) {
                throw new Error('Error: Could not retrieve instance details.');
            }
            const instanceId = (endpoint as OneInstanceEndpoint).instanceId;
            const {name} = (await getObjectByIdHash(instanceId)).obj;
            return {id: instanceId, name: name, isLocal: 'false', owner: mainProfile.personId};
        }
    }

    function instanceCardContent(): ReactElement {
        return (
            <>
                {instanceSettingsDetails !== undefined && (
                    <>
                        <Typography sx={{fontSize: 12}} color="text.secondary">
                            {i18n.t('settings:ownerName')}
                        </Typography>
                        <Typography
                            className={'instance-setting-item-margin'}
                            variant="body1"
                            component="div"
                        >
                            {instanceSettingsDetails.ownerName}
                        </Typography>
                        <Typography sx={{fontSize: 12}} color="text.secondary">
                            {i18n.t('settings:deviceName')}
                        </Typography>
                        <Typography
                            className={'instance-setting-item-margin'}
                            variant="body1"
                            component="div"
                        >
                            {instanceSettingsDetails.deviceName}
                        </Typography>
                        <Typography sx={{fontSize: 12}} color="text.secondary">
                            {i18n.t('settings:isLocal')}
                        </Typography>
                        <Typography
                            className={'instance-setting-item-margin'}
                            variant="body1"
                            component="div"
                        >
                            {instanceSettingsDetails.instance.isLocal}
                        </Typography>
                        <Typography sx={{fontSize: 12}} color="text.secondary">
                            {i18n.t('settings:instanceOwner')}
                        </Typography>
                        <Typography
                            className={'instance-setting-item-margin'}
                            variant="body1"
                            component="p"
                        >
                            {instanceSettingsDetails.instance.owner}
                        </Typography>
                        <Typography sx={{fontSize: 12}} color="text.secondary">
                            {i18n.t('settings:instanceName')}
                        </Typography>
                        <Typography
                            className={'instance-setting-item-margin'}
                            variant="body1"
                            component="div"
                        >
                            {instanceSettingsDetails.instance.name}
                        </Typography>
                        <Typography sx={{fontSize: 12}} color="text.secondary">
                            {i18n.t('settings:instanceId')}
                        </Typography>
                        <Typography
                            className={'instance-setting-item-margin'}
                            variant="body1"
                            component="div"
                        >
                            {instanceSettingsDetails.instance.id}
                        </Typography>
                    </>
                )}
            </>
        );
    }

    function instanceCardActions(): ReactElement {
        return (
            <>
                {instanceSettingsDetails !== undefined &&
                    instanceSettingsDetails.instance.isLocal === 'true' && (
                        <Button
                            onClick={onDeleteInstanceButtonPress}
                            color="error"
                            className={'delete-instance-button'}
                            variant="outlined"
                            size="small"
                        >
                            {i18n.t('settings:deleteModal.delete')}
                        </Button>
                    )}
            </>
        );
    }

    return (
        <div className="instance-settings-wrapper">
            <DeleteInstanceModal
                open={deleteInstanceModal}
                handleDelete={handleDeleteInstance}
                handleClose={closeDeleteInstanceModal}
            />
            <div className="view-title">Instance</div>
            <CustomCard content={instanceCardContent()} action={instanceCardActions()} />
        </div>
    );
}
