import type {RefObject} from 'react';
import {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {isMobile} from 'react-device-detect';

export function useWindowDimensions(): [number, number] {
    const [dimensions, setDimensions] = useState<[number, number]>([
        window.innerWidth,
        window.innerHeight
    ]);

    useLayoutEffect(() => {
        const updateWidthAndHeight = () => {
            setDimensions([window.innerWidth, window.innerHeight]);
        };
        window.addEventListener('resize', updateWidthAndHeight);
        return () => window.removeEventListener('resize', updateWidthAndHeight);
    }, []);

    return dimensions;
}

export function useWindowWidth(): number {
    const [width, setWidth] = useState(window.innerWidth);

    useLayoutEffect(() => {
        const updateWidth = () => {
            setWidth(window.innerWidth);
        };
        window.addEventListener('resize', updateWidth);
        return () => window.removeEventListener('resize', updateWidth);
    }, []);

    return width;
}

export function useWindowHeight(): number {
    const [height, setHeight] = useState(window.innerHeight);

    useLayoutEffect(() => {
        const updateHeight = () => {
            setHeight(window.innerHeight);
        };
        window.addEventListener('resize', updateHeight);
        return () => window.removeEventListener('resize', updateHeight);
    }, []);

    return height;
}

export function useRefDimensions(ref: RefObject<HTMLElement>): [number, number] {
    const [dimensions, setDimensions] = useState<[number, number]>([0, 0]);

    useLayoutEffect(() => {
        if (ref.current) {
            setDimensions([ref.current.clientWidth, ref.current.clientWidth]);
        }
    }, [ref]);

    return dimensions;
}

export function useVHPhoneFixRef<T extends HTMLElement>(addHeight: number = 0) {
    // https://stackoverflow.com/questions/37112218/css3-100vh-not-constant-in-mobile-browser
    const windowHeight = useWindowHeight();
    const fixRef = useRef<T>(null);
    useEffect(() => {
        if (isMobile && fixRef.current !== null) {
            fixRef.current.style.height = `${windowHeight + addHeight}px`;
        }
    }, [windowHeight, fixRef, addHeight]);

    return fixRef;
}

export function useVHPhoneFixById(elementId: string, addHeight: number = 0) {
    // https://stackoverflow.com/questions/37112218/css3-100vh-not-constant-in-mobile-browser
    const windowHeight = useWindowHeight();
    useEffect(() => {
        if (isMobile) {
            const element = document.getElementById(elementId);
            if (element) {
                element.style.height = `${windowHeight + addHeight}px`;
            }
        }
    }, [windowHeight, elementId, addHeight]);
}
