import type {ReactElement} from 'react';
import {useMarkdownContext} from '../context/MarkdownPopupContext';
import './Footer.css';
import {useTranslation} from 'react-i18next';

export default function Footer(): ReactElement {
    const {setMarkdownFilePath} = useMarkdownContext();
    const i18n = useTranslation();

    return (
        <div className="blut-footer">
            <div className="links">
                <div
                    className="footer-link"
                    onClick={() => setMarkdownFilePath(i18n.t('blut:mdFiles.impressum'))}
                >
                    {i18n.t('blut:impressum')}
                </div>
                <div className="slash-separator">/</div>
                <div
                    className="footer-link"
                    onClick={() => setMarkdownFilePath(i18n.t('blut:mdFiles.termsOfUse'))}
                >
                    {i18n.t('blut:terms')}
                </div>
                <div className="slash-separator">/</div>
                <div
                    className="footer-link"
                    onClick={() => setMarkdownFilePath(i18n.t('blut:mdFiles.privacyPolicyPWA'))}
                >
                    {i18n.t('blut:privacyPolicy')}
                </div>
            </div>
        </div>
    );
}
