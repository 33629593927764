import ReactDOM from 'react-dom';
import './index.css';
import Ui from './ui/Ui';
import {createTheme, StyledEngineProvider, ThemeProvider} from '@mui/material/styles';
import Model from './models/Model';
import {generateDynamicManifest} from './ui/Utils';
import {loadConfig} from './config';
import {start} from '@refinio/one.core/lib/logger';

const theme = createTheme({
    palette: {
        primary: {
            main: '#4473C5'
        }
    }
});

/**
 * Enable the logger with the specified types.
 *
 * @param logTypes
 */
function enableLogger(
    logTypes: ('error' | 'alert' | 'log' | 'debug')[] = ['error', 'alert', 'log']
): void {
    // Start the logger
    start({
        includeInstanceName: false,
        includeTimestamp: true,
        types: logTypes
    });
}

/**
 * Starts the whole application.
 */
async function startApplication(): Promise<void> {
    // Load the configuration
    const config = await loadConfig('/config.json');

    // Configure logging
    if (config.enableLogging) {
        enableLogger(config.logTypes);
    }

    // Instantiate the models
    const model = new Model();
    

    if (process.env.NODE_ENV === 'development') {
        // This is just a "hack" to expose model in the browser context for testing and debugging
        (window as any).model = model

        // Incase `?model_test=true` is passed via the URL parameters, we will not render a gui, but expose the model object globally.
        // Not rendering a GUI will speed up loading, which we want for testing.
        const queryParams = new URLSearchParams(window.location.search);
        if (queryParams.get('model_test') === "true") {
            return
        }

    }

    void generateDynamicManifest();

    ReactDOM.render(
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <Ui model={model} />
            </ThemeProvider>
        </StyledEngineProvider>,
        document.getElementById('root')
    );
}


// Just keep this unhandled, so when initialization fails we just get the unhandled rejection
// message that displays nice errors (at least for developers!)
void startApplication();
