import {Button} from '@mui/material';
import type {ReactElement} from 'react';
import {useEffect} from 'react';
import {useState} from 'react';
import './ButtonSelect.css';

export interface ButtonSelectOption {
    text: string;
    icon?: ReactElement;
    value: string;
}

export function ButtonSelect(props: {
    className?: string;
    options: ButtonSelectOption[];
    onSelect: (newValue: string) => void;
    onDeselect?: (oldValue: string) => void;
    selected?: string;
    enableDeselect?: boolean;
}): ReactElement {
    const [selected, setSelected] = useState<string>('');

    useEffect(() => {
        if (props.selected) {
            setSelected(props.selected);
        }
    }, [props.selected]);

    const changeSelected = (changeValue: string) => {
        if (selected !== changeValue) {
            setSelected(changeValue);
            props.onSelect(changeValue);
            return;
        }
        if (props.enableDeselect) {
            setSelected('');
        }
        if (props.onDeselect) {
            props.onDeselect(changeValue);
        }
    };

    return (
        <div className={`button-select-container${props.className ? ` ${props.className}` : ''}`}>
            {props.options.map((option, index) => (
                <Button
                    key={`button-select-buton-${index}-${option.text}${
                        props.className ? `-${props.className}` : ''
                    }`}
                    className={`button-select-buton big-rounded ${
                        selected === option.value ? 'blue-bg' : 'white-bg'
                    }`}
                    onClick={() => {
                        changeSelected(option.value);
                    }}
                >
                    <div className="button-select-inner-button-container">
                        <div className="button-select-inner-button-left" />
                        <div className="button-select-inner-button-center">{option.text}</div>
                        <div className="button-select-inner-button-right">
                            <div className="box">{option.icon}</div>
                        </div>
                    </div>
                </Button>
            ))}
        </div>
    );
}
