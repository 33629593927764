import type {ReactElement} from 'react';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {differenceInDays} from 'date-fns';

import {Button} from '@mui/material';

import {getLocale} from '../../i18n';
import {DatePicker} from '../components/datepicker/DatePicker';
import './Cycle.css';
import CycleTracker from '../components/cycleTracker/CycleTracker';
import type {CycleText} from '../components/cycleTracker/types';
import type Model from '../../models/Model';
import {useDaysMeasured} from '../hooks/cycle/cycle';
import {isDateBetween} from '../Utils';

// TODO: get data from model instead of dummy data
const TEST_DATA = {
    periodLength: 7,
    progressDay: 6,
    range: {
        length: 30
    }
};

type CycleData = {
    periodLength: number;
    range: {
        start: Date;
        end: Date;
        length: number;
    };
    progressDay: number;
    daysMeasured: number[];
};

function useCycleData(date: Date, model: Model): CycleData | undefined {
    const [cycleData, setCycleData] = useState<CycleData | undefined>(undefined);
    const daysMeasured = useDaysMeasured(model, cycleData?.range.start, cycleData?.range.end);

    // TODO: remove dummy data
    function getTestData() {
        const progressDay = TEST_DATA.progressDay;
        const cycleLength =
            TEST_DATA.range.length < progressDay ? progressDay : TEST_DATA.range.length;
        const startDate = new Date();
        startDate.setHours(0, 0, 0, 0);
        startDate.setDate(startDate.getDate() - progressDay);
        const endDate = new Date(startDate);
        endDate.setDate(endDate.getDate() + cycleLength);
        return {
            ...TEST_DATA,
            range: {
                length: cycleLength,
                start: startDate,
                end: endDate
            },
            daysMeasured: []
        };
    }

    // initial data retrival
    useEffect(() => {
        // TODO: get updated data from model instead of simulating it with dummy data
        setCycleData(getTestData());
    }, []);

    /**
     * On selection date change, update data
     */
    useEffect(() => {
        // TODO: get updated data from model instead of simulating it
        if (cycleData) {
            const daysSinceStart = differenceInDays(date, cycleData.range.start);

            if (daysSinceStart <= 0 || daysSinceStart > cycleData.range.length) {
                setCycleData(undefined);
            } else {
                setCycleData(getTestData());
            }
        } else {
            const testData = getTestData();
            if (isDateBetween(date, testData.range.start, testData.range.end)) {
                setCycleData(testData);
            }
        }
    }, [date]);

    return cycleData ? {...cycleData, daysMeasured} : undefined;
}

export default function Cycle(props: {model: Model}): ReactElement {
    const i18n = useTranslation();
    const navigate = useNavigate();
    const TODAY = new Date();
    TODAY.setHours(0, 0, 0, 0);

    const [date, setDate] = useState<Date>(TODAY);
    const cycleData = useCycleData(date, props.model);

    const navigateToTrack = (): void => {
        const day = date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`;
        const monthNumber = date.getMonth() + 1;
        const month = monthNumber < 10 ? `0${monthNumber}` : `${monthNumber}`;
        const year = date.getFullYear();
        navigate(`/track/${day}/${month}/${year}`);
    };

    const onCycleSelection = (day: number) => {
        if (cycleData) {
            const newDate = new Date(cycleData.range.start);
            newDate.setDate(newDate.getDate() + day);
            setDate(newDate);
        }
    };

    function setCycleInformation(day: number): CycleText[] {
        if (cycleData) {
            const periodDays = day - cycleData.periodLength;
            if (periodDays < 0) {
                return [
                    {
                        text: `${Math.abs(periodDays)} more day of period`,
                        fontWeight: 'bold'
                    },
                    {text: 'you are not fertile today'}
                ];
            } else if (day <= 23 && day >= 13) {
                return [{text: 'You are fertile today', fontWeight: 'bold'}];
            }
        }
        return [];
    }

    return (
        <>
            <div className="cycle-container">
                <div className="cycle-datepicker">
                    <DatePicker initialDate={date} disableChange={true} getLocale={getLocale} />
                </div>
                <div className="cycle-content">
                    {cycleData && (
                        <CycleTracker
                            cycleLength={cycleData.range.length}
                            currentDay={cycleData.progressDay}
                            selectedDay={differenceInDays(date, cycleData.range.start)}
                            segmentSections={[
                                {
                                    start: 1,
                                    end: cycleData.periodLength,
                                    color: '#EA3C53'
                                },
                                {
                                    start: 13,
                                    end: 23,
                                    color: '#00FEC2'
                                }
                            ]}
                            innerIndicators={cycleData.daysMeasured.map(dayNumber => ({
                                color: '#EA3C53',
                                day: dayNumber
                            }))}
                            circleEndAddition={{
                                angle: 30,
                                strokeWidth: 35,
                                color: '#FAD1D6',
                                strokeDasharray: `0, 60`
                            }}
                            onSelectedDay={onCycleSelection}
                            setInfoTextCallback={setCycleInformation}
                        />
                    )}
                </div>
            </div>
            <div className="cycle-button-container">
                <Button className="cycle-track big-rounded blue-bg" onClick={navigateToTrack}>
                    {i18n.t('common:track')}
                </Button>
            </div>
        </>
    );
}
