import {initReactI18next} from 'react-i18next';
import i18nModelsInstance from 'one.models/lib/i18n';
import LanguageDetector from 'i18next-browser-languagedetector';

import deLocale from 'date-fns/locale/de';
import enLocale from 'date-fns/locale/en-US';
import type Locale from 'date-fns';

import addToHomeScreen_de from './translations/de/addToHomeScreen.json';
import common_de from './translations/de/common.json';
import settings_de from './translations/de/settings.json';
import blut_de from './translations/de/blut.json';
import datepicker_de from './translations/de/datepicker.json';
import insights_de from './translations/de/insights.json';
import track_de from './translations/de/track.json';

const resources = {
    de: {
        addToHomeScreen: addToHomeScreen_de,
        common: common_de,
        blut: blut_de,
        settings: settings_de,
        datepicker: datepicker_de,
        insights: insights_de,
        track: track_de
    }
};

i18nModelsInstance
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: 'de',
        whitelist: ['de'],
        interpolation: {
            escapeValue: false
        }
    })
    .catch(error => {
        console.error(error);
    });

/**
 * get date locale based on i18n language
 * @param language i18n.language
 * @returns
 */
export function getLocale(language: string): Locale {
    if (language === 'en') {
        return enLocale;
    }
    return deLocale;
}

export default i18nModelsInstance;
