import type {ReactElement} from 'react';
import {Route, Routes, useNavigate} from 'react-router-dom';
import type {SHA256Hash, SHA256IdHash} from '@refinio/one.core/lib/util/type-checks';
import type {ChannelInfo} from 'one.models/lib/recipes/ChannelRecipes';
import ChannelsView from 'one.ui/lib/ui/views/debug/channels/ChannelsView';
import ChannelDetailedView from 'one.ui/lib/ui/views/debug/channels/ChannelDetailedView';
import type {ChannelManager, LeuteModel} from 'one.models/lib/models';
import type InstancesModel from 'one.models/lib/models/InstancesModel';
import {ObjectTreeScreen} from 'one.ui/lib/ui/views/debug/oneBrowser/ObjectTreeScreen';
import {ObjectsTableScreen} from 'one.ui/lib/ui/views/debug/oneBrowser/ObjectsTableScreen';
import './RegisterDebugRoutes.css';

/**
 * This component registers the debug routes
 * @param props
 * @constructor
 */
export default function RegisterDebugRoutes(props: {
    channelManager: ChannelManager;
    instancesModel: InstancesModel;
    leuteModel: LeuteModel;
}): ReactElement {
    const navigate = useNavigate();

    async function onOneBrowserObjectSelect(objectHash: SHA256IdHash): Promise<void> {
        navigate(`object/versioned/${objectHash}`);
    }

    function onChannelSelect(version: string, hash: SHA256Hash<ChannelInfo>): void {
        navigate(`channelsView/${version}/${hash}`);
    }

    return (
        <Routes>
            <Route
                path="oneBrowser"
                element={
                    <div className="debug-container">
                        <ObjectsTableScreen onObjectSelected={onOneBrowserObjectSelect} />
                    </div>
                }
            />
            <Route
                path="object/*"
                element={
                    <div className="debug-container">
                        <ObjectTreeScreen />
                    </div>
                }
            />
            <Route
                path="channelsView"
                element={
                    <div className="debug-container">
                        <ChannelsView onChannelSelected={onChannelSelect} />
                    </div>
                }
            />
            <Route
                path="channelsView/:channelRegistryHash/:channelHash"
                element={
                    <div className="debug-container">
                        <ChannelDetailedView channelManager={props.channelManager} />
                    </div>
                }
            />
        </Routes>
    );
}
