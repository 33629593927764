import type {ReactElement} from 'react';
import {Avatar} from '@mui/material';
import {
    AccountBoxOutline,
    AccountVoice,
    Devices,
    InformationOutline,
    Refresh,
    ListStatus
} from 'mdi-material-ui';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import './Icon.css';

export const ICONS = {
    Contacts: AccountBoxOutline,
    Devices: Devices,
    FileCopy: FileCopyIcon,
    Info: InformationOutline,
    Refresh: Refresh,
    Status: AccountVoice,
    Insights: ListStatus
} as const;

/**
 * Builds an avatar with an icon, whose name it receives as a parameter
 * @param props
 * @param props.name - icon name
 * @returns {ReactElement}
 */
export function Icon(props: {name: string}): ReactElement {
    const IconType = ICONS[props.name as keyof typeof ICONS];

    return (
        <Avatar className={`icon-avatar-wrapper ${props.name}`}>
            <IconType className="icon-avatar" />
        </Avatar>
    );
}
