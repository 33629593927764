import {useContext} from 'react';
import type {ReactElement} from 'react';
import type {SvgIconProps} from '@mui/material';
import {AppBar as AppBarMui, IconButton, Toolbar} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import MoreIcon from '@mui/icons-material/MoreVert';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DownArrowIcon from '@mui/icons-material/KeyboardArrowDown';
import './AppBar.css';
import {AppBarContext} from '../context/AppBarContext';
import {useTranslation} from 'react-i18next';

export const APP_BAR_MODE = {
    VIEW: 'view',
    BROWSE: 'browse',
    EDIT: 'edit',
    CHEVRON: 'chevron'
} as const;

export type AppBarMode = typeof APP_BAR_MODE[keyof typeof APP_BAR_MODE];

type AppBarIcon = {
    leftIcon: IconProps;
    rightIcon?: IconProps;
};

type IconProps = {
    icon: ReactElement<SvgIconProps>;
    functionality: () => void;
};

/**
 * Builds the app bar
 */
export default function AppBar(): ReactElement | null {
    const {contextValue, setContextValue} = useContext(AppBarContext);
    const {i18n} = useTranslation();

    /**
     * Handler for the Menu button click event, by saving the state in the AppBarContext
     */
    function handleMenuBtnClick(): void {
        setContextValue({...contextValue, isMenuBtnClicked: true});
    }

    /**
     * Handler for the More button(three dots button) click event, by saving the state in the AppBarContext
     */
    function handleMoreBtnClick(): void {
        setContextValue({...contextValue, isMoreBtnClicked: true});
    }

    function getAppBarMode(appBarIcon: AppBarIcon): ReactElement {
        return (
            <>
                <IconButton
                    edge="start"
                    color="inherit"
                    size="large"
                    onClick={appBarIcon.leftIcon.functionality}
                >
                    {appBarIcon.leftIcon.icon}
                </IconButton>
                <div className="app-bar-title">{i18n.t(contextValue.title)}</div>
                <div className="app-bar-grow" />
                {appBarIcon.rightIcon && (
                    <IconButton
                        edge="end"
                        color="inherit"
                        size="large"
                        onClick={appBarIcon.rightIcon.functionality}
                    >
                        {appBarIcon.rightIcon.icon}
                    </IconButton>
                )}
            </>
        );
    }

    const toolbar = {
        [APP_BAR_MODE.BROWSE]: getAppBarMode({
            leftIcon: {icon: <MenuIcon />, functionality: handleMenuBtnClick},
            rightIcon: undefined
        }),
        [APP_BAR_MODE.VIEW]: getAppBarMode({
            leftIcon: {icon: <MenuIcon />, functionality: handleMenuBtnClick},
            rightIcon: {icon: <MoreIcon />, functionality: handleMoreBtnClick}
        }),
        [APP_BAR_MODE.EDIT]: getAppBarMode({
            leftIcon: {
                icon: <CloseIcon />,
                functionality: contextValue.onClose
            },
            rightIcon: {
                icon: <CheckIcon />,
                functionality: contextValue.onDone
            }
        }),
        [APP_BAR_MODE.CHEVRON]: getAppBarMode({
            leftIcon: {
                icon: <DownArrowIcon />,
                functionality: contextValue.onClose
            }
        })
    };

    return contextValue.hide ? null : (
        <AppBarMui position="fixed" className="app-bar-container">
            <Toolbar>{toolbar[contextValue.mode]}</Toolbar>
        </AppBarMui>
    );
}
